/*
 *   File : cart-invest.js
 *   Author URI : https://evoqins.com
 *   Description : Popup component which creates UI for Cart investment.
 *   Integrations : null
 *   Version : v1.1
 */

import { Fragment, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import parse from 'html-react-parser';
import { Tooltip } from "react-tooltip";

import Icon from "../Icon/icon";
import { DataTable, OTPInput, RadioGroup, CustomTextInput } from "../Form-elements";
import { _convertToIdLabelArray, useClickOutside } from "../../Helper";
import { CustomToolTip } from "../Popups";
import { GradientButton } from "../CTA";

import APIService from "../../Service/api-service";

import { _consent2FA } from '../../Helper/api';

import style from "../../Styles/Component/cart-invest.module.scss"
import Colors from "../../Styles/color.module.scss";


const PAYMENT_TYPE = [
    { id: 2, label: 'Net banking' },
    { id: 1, label: 'UPI' },
    { id: 3, label: 'NEFT/RTGS' }
]

const CartInvestModal = (props) => {

    var timer;
    const FolioRef = useRef();
    const paymentInputRef = useRef(null);

    const TOOL_TIP = useSelector(state => state.generalData.TOOL_TIP_DATA);
    const PROFILE_DATA = useSelector(state => state.generalData.PROFILE_DATA);

    const [selectedItems, setSelectedItems] = useState([])
    const [showFolioDropDown, setShowFolioDropDown] = useState(null);
    const [showBank, setShowBank] = useState(true);
    const [selectedBank, setSelectedBank] = useState({});
    const [showMessage, setShowMessage] = useState(false);
    const [step, setStep] = useState(1);
    const [pinString, setPinString] = useState("");
    const [pinError, setPinError] = useState("");
    const [cartItems, setCartItems] = useState([]);
    const [apiLoader, setApiLoader] = useState(false);
    const [seconds, setSeconds] = useState(30);
    const [paymentOtpRequired, setPaymentOtpRequired] = useState(true);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(2); // 1 -> UPI, 2 -> Net banking
    const [upiId, setUpiId] = useState('');
    const [upiIdError, setUpiIdError] = useState('');
    const [upiTimerSecond, setUpiTimerSecond] = useState(600);
    const [paymentId, setPaymentId] = useState(null);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [utrNumber, setUtrNumber] = useState("");
    const [utrNumberError, setUtrNumberError] = useState("");
    const [openUTRInstruction, setOpenUTRInstruction] = useState(false);
    const [showUTRInstructions, setShowUTRInstructions] = useState(false);


    const CART_COLUMNS = [
        {
            name: 'Investing funds',
            selector: row => row['name'],
            sortable: false,
            // width: "546px",
            cell: row =>
                <div className="d-flex align-items-center gap-8px e-fund-name ">
                    <div className="p-1 border-radius-4px border-all border-bright-gray w-unset bg-white">
                        <img src={row['image']} alt={row['name']}
                            width={40}
                            height={40}
                            className="object-fit-contain" />
                    </div>

                    <div className="d-flex flex-column ">
                        <span className="color-black e-poppins-medium e-font-12 line-height-16px mb-0">{row['name']}</span>
                        {
                            PROFILE_DATA.demat === true ?
                                <span className="border-radius-16px color-polished-pine e-poppins-medium e-font-10 line-height-18px padding-6px-lr py-1 border-polished-pine border-all bg-pale-turquoise w-max-content">Demat</span>
                                :
                                <div className="border-radius-4px border-all border-bright-gray py-1 px-2 d-flex align-items-center justify-content-between w-90 position-relative cursor-pointer mt-1"
                                    onClick={() => _handleFolioClick(row.id)}>
                                    <p className="color-primary-color e-poppins-medium e-font-10 line-height-12px mb-0">{row.selected_folio === null ? "Create new folio" : `Folio no: ${row.selected_folio}`}</p>
                                    <Icon icon="calender-arrow-right"
                                        color={Colors.primary}
                                        size={12}
                                        className={showFolioDropDown === row.id ? style.e_rotate_left_90 : style.e_rotate_right_90} />
                                    {
                                        showFolioDropDown === row.id &&
                                        <div className={`border-radius-8px border-width-2px border-solid border-bright-gray bg-cards position-absolute left-0 w-100 z-index-1 ${style.e_folio}`}
                                            ref={FolioRef}>
                                            {
                                                row.folios.map((item, key) =>
                                                    <div className={`d-flex align-items-center justify-content-between p-2 cursor-pointer ${row.folios.length !== key + 1 && "border-bottom-1px border-bright-gray"}`}
                                                        key={key}
                                                        onClick={(e) => _updateSelectedFolio(row.id, item.id, e)}>
                                                        {console.log('row', row)}
                                                        <p className="color-black e-poppins-regular e-font-10 line-height-12px mb-0">{item.label}</p>
                                                        <Icon icon={row.selected_folio === item.id ? "radio-selected" : "radio-unselected"}
                                                            size={14} />
                                                    </div>
                                                )
                                            }
                                        </div>
                                    }
                                </div>
                        }

                    </div>

                </div>
        },
        {
            name: "NAV",
            width: "130px",
            selector: row => row['nav'],
            sortable: false,
            cell: row =>
                <div>
                    <p className="color-black e-poppins-semi-bold e-font-14 line-height-26px mb-0">
                        {row['nav'] !== '-' ? (
                            <span className="e-inter-semi-bold">₹{row['nav'].toLocaleString("en-IN")}</span>
                        ) : (
                            row['nav']
                        )} <span className={`e-font-10 color-sea-green ${row['nav_change'] < 0 && 'color-red'}`}>
                            ({row['nav_change_percentage']})
                        </span>
                    </p>
                    <p className="color-gray e-poppins-regular e-font-10 line-height-12px mb-0">(as on {row['nav_date']})</p>
                </div>

        },
        {
            name: 'Amount',
            selector: row => row['amount'],
            sortable: false,
            width: "100px",
            cell: row =>
                <p className="color-black e-poppins-semi-bold e-font-16 line-height-26px mb-0 ">
                    <span className="e-inter-semi-bold">₹</span>{row['amount'].toLocaleString("en-IN")}
                </p>
        }
    ];

    useEffect(() => {
        var my_modal = document.getElementById("cart-invest");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
            clearInterval(timer);
        });
    }, []);

    useEffect(() => {
        if (step === 3) {
            _checkUPIStatus();
            const interval = setInterval(() => {
                setUpiTimerSecond(prevSeconds => {
                    if (prevSeconds === 0) {
                        clearInterval(interval);
                        let close_button = document.getElementById("close-modal");
                        close_button.click();
                        props.getPaymentStatus(paymentId);
                    }
                    return prevSeconds - 1;
                });
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [step]);


    useEffect(() => {
        if (props.selectedItems !== 0) {
            const updatedData = props.selectedItems.map(item => {
                let convertedFolios = _convertToIdLabelArray(item.folios);
                convertedFolios.unshift({
                    id: null,
                    label: "Create new folio"
                });
                console.log('item', item);
                return {
                    ...item,
                    selected_folio: item.folios.length === 0 ? null : item.folios[0].id,
                    folios: convertedFolios
                };
            });

            setSelectedItems(updatedData);
        }
        const default_item = props.bankDetails.find(item => item.primary === true);
        if (default_item !== undefined) {
            setSelectedBank(default_item);
        }
    }, [props.selectedItems]);

    useEffect(() => {
        if (step === 2) {
            const countdown = setInterval(() => {
                if (seconds > 0) {
                    setSeconds(seconds - 1);
                } else {
                    clearInterval(countdown);
                    // Handle expiration logic here (e.g., show a resend button)
                }
            }, 1000);

            return () => {
                clearInterval(countdown);
            };
        } else {
            setSeconds(30);
        }
    }, [seconds, step]);

    // Update cart_items whenever selected folio changes
    useEffect(() => {
        setCartItems(prevFolioData => {
            const updated_folio_data = selectedItems.map(item => ({
                cart_detail_id: item.id,
                folio_number: PROFILE_DATA.demat === true ? "" : item.selected_folio
            }));

            return updated_folio_data;
        });
    }, [selectedItems]);

    useClickOutside(FolioRef, () => {
        setShowFolioDropDown(null);
    });

    const _updateSelectedFolio = (rowId, value, e) => {
        e.stopPropagation();
        const updated_selected_data = [...selectedItems];
        const row_to_update = updated_selected_data.find(item => item.id === rowId);

        // Update the selected_folio property
        if (row_to_update) {
            row_to_update.selected_folio = value;
            setSelectedItems(updated_selected_data);
        }
        setShowFolioDropDown(null);
    };

    const _handleFolioClick = (id) => {
        setShowFolioDropDown(id);
    }

    const _handleSelectBank = () => {
        setShowBank(!showBank)
    }

    const _handleSelectedBank = (data) => {
        setSelectedBank(data);
    }

    const _handleShowMessage = () => {
        setShowMessage(true);
    }

    const _handleHideMessage = () => {
        setShowMessage(false);
    }

    const _handleGoback = () => {
        setPinString("");
        setPinError("");
        setStep(1);
    }

    const _handlePin = (value) => {
        setPinString(value);
        setPinError("");
    }

    // API - confirm payment
    async function _invest() {
        if (step === 2) {
            setSeconds(30);
        }
        setButtonLoader(true);
        let url = 'cart/confirm-invest';
        let payload = JSON.stringify({
            cart_items: cartItems,
            bank_id: selectedBank.bank_id
        });

        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setPaymentOtpRequired(response.data.otp_required);
                if (response.data.otp_required === false) {
                    _confirmPayment();
                } else {
                    toast.dismiss();
                    toast.success("OTP sent to your registered email and phone number", {
                        type: "success",
                    });
                    setStep(2);
                }

            } else {
                toast.error(response.message, {
                    type: "error"
                });
            }
            setButtonLoader(false);
        });
    }

    const _resendOTP = () => {
        _consent2FA("CART")
            .then((response) => {
                toast.dismiss();
                toast.success("OTP sent to your registered email and phone number", {
                    type: "success",
                });
                setSeconds(30);
                setPinString("");
            }).catch((response) => {
                toast.dismiss();
                toast.error(response, {
                    type: "error"
                });
            });
    }

    // validate pin
    const _validatePin = () => {
        let valid = true;
        if (pinString.length === 0) {
            setPinError("OTP Required");
            valid = false;
        }
        if (selectedPaymentMethod === 1) {
            if (upiId.length === 0) {
                paymentInputRef.current.focus();
                setUpiIdError("UPI Id can't be empty");
                valid = false;
            }

            if (upiId.includes("@") === false) {
                paymentInputRef.current.focus();
                setUpiIdError("Invalid UPI Id");
                valid = false;

            }
        }
        if (selectedPaymentMethod === 3 && utrNumber === "") {
            setUtrNumberError("UTR number can't be empty");
            paymentInputRef.current.focus();
            valid = false;
        }
        if (valid === true) {
            // console.log("valid")
            _confirmPayment();
        }
    }

    // API - confirm investment in cart create payment URL
    async function _confirmPayment() {
        setApiLoader(true);
        let url = 'cart/invest';
        let payload = JSON.stringify({
            cart_items: cartItems,
            bank_id: selectedBank.bank_id,
            otp: pinString,
            upi_id: upiId,
            neft_number: utrNumber,
            payment_type: selectedPaymentMethod === 1 ? "UPI" : selectedPaymentMethod === 2 ? "NETBANKING" : "NEFT_RTGS"
        });

        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                if (selectedPaymentMethod === 1) {
                    setStep(3);
                } else if (selectedPaymentMethod === 3) {
                    toast.success(response.data.payment_msg);
                    const close_button = document.getElementById("modal-close");
                    close_button.click();
                    props.refreshCart();
                    // props.getPaymentStatus(null, firstInvestment === 2 && true, selectedPaymentMethod);
                    return;
                }else {
                    if (response.data.payment_url !== null) {
                        _openPaymentURL(response.data.payment_url, response.data.payment_html, response.data.payment_id);

                    } else {
                        _paymentCallback(response.data.payment_id);
                    }
                }
                setPaymentId(response.data.payment_id);

            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
                setApiLoader(false);
            }
        });
    }

    // Function for open payment url from invest api;
    function _openPaymentURL(url, html, payment_id) {


        setTimeout(() => {
            var new_window;

            // In production we expecting string of html and convert given string as html and opening in new window
            // In other env we expecting url and directly open it in new window
            if (process.env.REACT_APP_ENV == 'PROD') {
                const winUrl = URL.createObjectURL(
                    new Blob([html], { type: "text/html" })
                );
                new_window = window.open(
                    winUrl,
                    "win",
                    `width=800,height=800,screenX=200,screenY=200`
                );
            } else {
                new_window = window.open(url, '_blank', 'width=800,height=800');
            }

            if (new_window !== null) {
                new_window.opener = null;
                timer = setInterval(function () {
                    if (new_window.closed) {
                        clearInterval(timer);
                        _paymentCallback(payment_id);
                    }
                }, 1000);
            } else {
                toast.dismiss();
                toast.error('Popups are disabled in your browser. Please allow popups to open the payment gateway.', {
                    type: 'error'
                });
            }
        }, 100);
    }

    function _checkUPIStatus() {
        let url = 'payment/poll-status';
        let payload = JSON.stringify({
            payment_id: paymentId
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                if (response.data.payment_status_txt === 'pending') {
                    setTimeout(_checkUPIStatus, 1000);
                } else {
                    let close_button = document.getElementById("close-modal");
                    close_button.click();
                    props.getPaymentStatus(paymentId);
                }
            }
        })
    }

    function _paymentCallback(payment_id) {
        document.getElementById("close-modal").dispatchEvent(new Event("click"));
        props.getPaymentStatus(payment_id, selectedPaymentMethod);
    }

    const _handlePaymentRadio = (id) => {
        setSelectedPaymentMethod(id);
    }

    // update input value
    const _handleInputChange = (value) => {
        setUpiId(value);
        setUpiIdError("");
    }

    const _handleUTRChange = (value) => {
        setUtrNumber(value);
        setUtrNumberError("");
    }

    // handle UTR instruction link
    function _openUTRInstructionModal() {
        setShowUTRInstructions(true);
    }

    function _closeUTRInstruction() {
        setShowUTRInstructions(false);
    }

    const customStyles = {
        table: {
            style: {
                backgroundColor: Colors.light_mint_green,
                borderRadius: "16px",
                padding: "16px"
            }
        },
        headRow: {
            style: {
                fontSize: "16px",
                padding: "0px !important",
                backgroundColor: "transparent",
                borderBottom: "none"
            }
        },
        headCells: {
            style: {
                '&:nth-child(3)': {
                    justifyContent: 'flex-end',
                },
            },
        },
        rows: {
            style: {
                padding: "0px",
                paddingBottom: "8px",
                marginTop: "8px",
                marginBottom: "0px",
                border: "none",
                borderRadius: "unset",
                borderBottom: `1px solid ${Colors.light_green} !important`,
                backgroundColor: "transparent",
            },
        },

        cells: {
            style: {
                '&:first-child': {
                    marginRight: "40px"
                },
                '&:nth-child(3)': {
                    justifyContent: 'flex-end',
                },
            }
        }
    };

    const SelectBank = ({ item }) => {
        console.log('Item :: ', item)
        return (
            <div className={`d-flex justify-content-between align-items-center cursor-pointer gap-8px h-100
                ${item.bank_id === selectedBank.bank_id ?
                    `border-radius-8px border-all padding-12px-all border-primary-color`
                    :
                    `border-radius-8px border-all padding-12px-all border-bright-gray`
                }`}
                onClick={() => _handleSelectedBank(item)}>
                <div className="d-flex align-items-center">
                    <img src={item.bank_image}
                        alt={item.name}
                        width={35}
                        height={35}
                        className="border-radius-100 me-1" />
                    <div className="d-flex flex-column">
                        <div className="d-flex align-items-center">
                            <span className="color-black e-poppins-semi-bold e-font-14 line-height-14px me-1 ">
                                {item.bank_name}
                            </span>
                            <span className="color-outer-space e-poppins-medium e-font-14 line-height-14px ">{item.masked_account_number}</span>
                        </div>
                        {
                            item.primary === true &&
                            <p className="color-primary-color e-poppins-medium e-font-12  line-height-12px mb-0 mt-1">Primary bank</p>
                        }
                    </div>
                </div>
                <Icon icon={
                    selectedBank.bank_id === item.bank_id ?
                        'radio-selected' :
                        'radio-unselected'
                }
                    size={20} />
            </div>
        )
    }

    return (
        <div className={`modal fade ${style.e_cart_modal}`}
            id="cart-invest"
            tabIndex="-1"
            aria-labelledby="cart-invest"
            aria-hidden="true"
            data-bs-keyboard="false"
            data-bs-backdrop="static" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_cart_dialog}`}>
                <div className={`modal-content pt-3 pb-4 px-4 ${style.e_cart_content} `}>
                    {
                        showUTRInstructions === true ?
                            <>
                                <div className="d-flex postion-relative">
                                    <Icon icon="arrow-right1"
                                        size={24}
                                        color={Colors.black}
                                        className={`cursor-pointer ${style.e_back}`}
                                        onClick={_closeUTRInstruction} />
                                    <p className="color-eerie-black ms-2 e-poppins-medium e-font-16 line-height-16px mb-0 mt-1">
                                        NEFT/RTGS details
                                    </p>
                                    <Icon icon="close"
                                        size={24}
                                        data-bs-dismiss="modal"
                                        className={`position-absolute cursor-pointer e-modal-close-btn ${style.e_close}`}
                                    />
                                </div>
                                <div className="overflow-auto e-scroll-container mt-3">
                                    <div className="col-12">
                                        <p className="color-black e-poppins-regular e-font-16">
                                            Please add the following account in your bank as beneficiary for NEFT/RTGS payments.
                                            Once the beneficiary is setup, you can place and pay for your orders in the following manner.</p>

                                        <ol>
                                            <li className="e-poppins-medium e-font-16 color-black">
                                                {"Individual /Savings Account - Fund Transfer > Transfer to eCMS Account > Third Party Transfer > Add a Beneficiary"}
                                            </li>
                                            <li className="e-poppins-medium e-font-16 color-black mt-3">
                                                {"Non-Individual /Current Account - Fund Transfer > Request > Add a Beneficiary > Transfer to eCMS account"}
                                            </li>
                                        </ol>
                                    </div>

                                    <div className="col-12 text-center">
                                        <p className="e-poppins-medium e-font-14 color-black">Beneficiary Details to be added for NEFT/RTGS transactions</p>
                                    </div>
                                    <div className="col-12">
                                        <table className="utr-instruction-table">
                                            <tr>
                                                <td>Beneficiary Name</td>
                                                <td>Indian Clearing Corporation Limited-MFD A/c</td>
                                            </tr>
                                            <tr>
                                                <td>Beneficiary a/c no</td>
                                                <td>MFDICC</td>
                                            </tr>
                                            <tr>
                                                <td>IFSC Code</td>
                                                <td>HDFC0000240</td>
                                            </tr>
                                            <tr>
                                                <td>Account type</td>
                                                <td>Current</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div className="col-12 text-center mt-3">
                                        <p className="e-poppins-medium e-font-14 color-bright-red">Only NEFT/RTGS model is supported. Do not transfer using IMPS or UPI mode.</p>
                                    </div>
                                    <div className="col-8 offset-2">
                                        <GradientButton label="I understood"
                                            className="e-font-16 padding-12px-tb w-100"
                                            handleClick={_closeUTRInstruction} />
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                {
                                    step === 1 ?
                                        <Fragment>
                                            <div className="d-flex align-items-center justify-content-between margin-20px-bottom">
                                                <p className="eerie-black e-poppins-medium e-font-15 line-height-16px mb-0">Investment details</p>
                                                <Icon icon="close"
                                                    data-bs-dismiss="modal"
                                                    id="close-modal"
                                                    size={24}
                                                    className="cursor-pointer e-modal-close-btn"
                                                    onClick={props.close} />
                                            </div>
                                            <div className={style.e_modal_body}>
                                                <div className="e-cart-invest-table">
                                                    <DataTable columns={CART_COLUMNS}
                                                        data={selectedItems}
                                                        pagination={false}
                                                        customStyles={customStyles}
                                                        defaultSortAsc={false} />
                                                </div>

                                                <div className="cursor-pointer d-flex align-items-center gap-8px w-100 justify-content-end mt-3 mb-2"
                                                    onClick={_handleSelectBank}>
                                                    <p className="color-sea-green e-poppins-medium e-font-12 mb-0">Select bank</p>
                                                    <Icon icon="right-arrow"
                                                        size={24}
                                                        className={showBank === true ? style.e_rotate_left_90 : style.e_rotate_right_90} />
                                                </div>
                                                {
                                                    showBank === true &&
                                                    <div className="border-radius-8px border-all border-bright-gray p-2 mt-3">
                                                        <p className="color-outer-space e-poppins-regular e-font-12 mb-1">Select bank</p>
                                                        <div className="row ">
                                                            {
                                                                props.bankDetails.map((item, key) =>
                                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
                                                                        <div className={`${style.select_bank_radio}`} key={key}>
                                                                            <SelectBank item={item} type={2} />
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                            </div>

                                            <div className="row mt-3 px-lg-5 px-md-5 px-0">
                                                <div className="col-12 px-lg-5 px-md-5 px-3">
                                                    <GradientButton label="Proceed"
                                                        className=" e-font-16 padding-12px-tb w-100"
                                                        loading={buttonLoader}
                                                        handleClick={_invest} />
                                                    <p className="color-black e-poppins-regular e-font-14 line-height-14px mb-0 d-flex justify-content-center align-items-center mt-2 position-relative">
                                                        <div>OTP will be sent on registered mobile/email</div>
                                                        {
                                                            Object.keys(TOOL_TIP).length !== 0 &&
                                                            <div className={`ms-1 cursor-pointer  align-items-center w-max-content ${style.e_mail_icon}`}
                                                                onMouseEnter={_handleShowMessage}
                                                                onMouseLeave={_handleHideMessage}>
                                                                <Icon icon="info"
                                                                    size={16}
                                                                    data-tooltip-id="email-tooltip" />
                                                                <Tooltip id="email-tooltip"
                                                                    arrowColor='transparent'
                                                                    effect="float"
                                                                    place="bottom"
                                                                    className='e-tooltip-general'>
                                                                    {parse(TOOL_TIP.invest_investment_popup)}
                                                                </Tooltip>
                                                            </div>
                                                        }

                                                    </p>
                                                </div>
                                            </div>
                                        </Fragment>
                                        : step === 2 ?
                                            <Fragment>
                                                <button id="modal-close"
                                                    data-bs-dismiss="modal"
                                                    className="d-none"></button>
                                                <Icon icon="close"
                                                    size={24}
                                                    id="close-modal"
                                                    data-bs-dismiss="modal"
                                                    className={`position-absolute e-modal-close-btn cursor-pointer ${style.e_close}`} />
                                                <Icon icon="arrow-right1"
                                                    size={24}
                                                    color={Colors.black}
                                                    className={`position-absolute cursor-pointer ${style.e_back} ${apiLoader === true && "no-events"}`}
                                                    onClick={_handleGoback} />
                                                <div className={`${style.e_modal_body} mt-5`}>
                                                    <div className="text-center ">
                                                        <img src={require("../../Assets/Images/explore/confirm-payment.png")}
                                                            alt="Confirm payment"
                                                            width={72}
                                                            height={72} />
                                                        {
                                                            paymentOtpRequired === true && (
                                                                <>
                                                                    <p className="color-eerie-black e-poppins-medium e-font-1 mb-2 mt-3">Enter OTP you have received in registered email and phone number</p>

                                                                    <p className="color-jett-black e-poppins-regular e-font-16 mb-4">You are one step away from making an investment</p>
                                                                    <OTPInput pinString={pinString}
                                                                        id="cart-input"
                                                                        autoFocus={true}
                                                                        pinError={pinError}
                                                                        disabled={apiLoader}
                                                                        handleChange={_handlePin} />
                                                                </>
                                                            )
                                                        }
                                                    </div>
                                                    <div className="row mb-4 mb-lg-0 mb-md-0 pb-2 mt-4 ">
                                                        <div className={`col-12 mb-3  ${paymentOtpRequired === true && 'd-flex align-items-center justify-content-center'}`}>
                                                            <p className="color-black e-poppins-medium e-font-14 mt-1 mb-0">Choose payment method</p>
                                                            <Icon icon="info"
                                                                size={16}
                                                                data-tooltip-id="payment-tooltip"
                                                                className="cursor-pointer ms-2 mt-1" />
                                                            <Tooltip id="payment-tooltip"
                                                                arrowColor='transparent'
                                                                effect="float"
                                                                place="bottom"
                                                                className='e-tooltip-general'>
                                                                Expected timeline for realization of funds as per payment mode.
                                                                <ul className="mb-0">
                                                                    <li>Net Banking (Direct*) - T day</li>
                                                                    <li>Net Banking (Nodal) - T+1 day</li>
                                                                    <li>UPI - T day</li>
                                                                </ul>


                                                                *Direct Banks – Axis, HDFC, ICICI, IDBI, Kotak, SBI & YES Bank

                                                            </Tooltip>
                                                        </div>
                                                        <div className="col-12  ">
                                                            <RadioGroup data={PAYMENT_TYPE}
                                                                radioItemClassName=" py-3 border-radius-16px e-payment-radio"
                                                                // size={14}
                                                                activeClass="border-pearl-aqua bg-frosty-cyan"
                                                                fontSize="e-font-12"
                                                                type={2}
                                                                id={selectedPaymentMethod}
                                                                recommendedBadge={true}
                                                                spanClass="e-poppins-semi-bold"
                                                                className="py-2 w-100 flex-sm-nowrap flex-wrap"
                                                                radioHandler={_handlePaymentRadio} />
                                                        </div>
                                                        {
                                                            selectedPaymentMethod === 1 &&
                                                            <div className="col-12 mt-2">
                                                                <CustomTextInput value={upiId}
                                                                    key={"UPI ID"}
                                                                    label="ADD UPI ID*"
                                                                    placeholder="sample@upi"
                                                                    error={upiIdError}
                                                                    inputRef={paymentInputRef}
                                                                    inputClass=" bg-transparent e-poppins-semi-bold padding-28px-left  border-radius-12px"
                                                                    labelClass="bg-modal e-font-16 line-height-26px"
                                                                    handleChange={(value) => _handleInputChange(value)} />
                                                            </div>
                                                        }
                                                        {
                                                            selectedPaymentMethod === 3 &&
                                                            <div className="col-12 mt-2">
                                                                <CustomTextInput value={utrNumber}
                                                                    key={"UTR ID"}
                                                                    label="Enter UTR number"
                                                                    placeholder="Add UTR number"
                                                                    error={utrNumberError}
                                                                    inputRef={paymentInputRef}
                                                                    inputClass=" bg-transparent e-poppins-semi-bold padding-28px-left  border-radius-12px"
                                                                    labelClass="bg-modal e-font-16 line-height-26px"
                                                                    handleChange={_handleUTRChange} />
                                                            </div>
                                                        }
                                                        {
                                                            selectedPaymentMethod === 3 &&
                                                            <div className="col-12 mt-3">
                                                                <p className="color-black e-poppins-light e-font-12 ">
                                                                    <a className="color-primary-color e-poppins-regular text-decoration-underline text-underline-offset-4 cursor-pointer"
                                                                        onClick={_openUTRInstructionModal}>
                                                                        Follow these instructions
                                                                    </a>
                                                                    {' '}
                                                                    to initiate NEFT/RTGS transfer and generate the UTR number
                                                                </p>
                                                            </div>
                                                        }

                                                    </div>
                                                    <div className=" mx-lg-5 mx-md-5 mx-0 px-lg-5 mt-3 px-md-5 px-3 mb-5">
                                                        <GradientButton label={selectedPaymentMethod === 3 ? "Pay with NEFT/RTGS" : "Verify OTP"}
                                                            className=" e-font-16 padding-12px-tb w-100"
                                                            loading={apiLoader}
                                                            handleClick={_validatePin} />
                                                        {
                                                            paymentOtpRequired === true && (
                                                                <>
                                                                    <p className="color-black e-poppins-regular e-font-14 line-height-14px mb-0 d-flex justify-content-center align-items-center mt-2 position-relative">
                                                                        <span>OTP will be sent on registered mobile/email</span>
                                                                        {
                                                                            Object.keys(TOOL_TIP).length !== 0 &&
                                                                            <div className={`ms-1 cursor-pointer  align-items-center w-max-content ${style.e_payment_icon}`}
                                                                                onMouseEnter={_handleShowMessage}
                                                                                onMouseLeave={_handleHideMessage}>
                                                                                <Icon icon="info"
                                                                                    size={16}
                                                                                    data-tooltip-id="mobile-tooltip" />
                                                                                <Tooltip id="mobile-tooltip"
                                                                                    arrowColor='transparent'
                                                                                    effect="float"
                                                                                    place="bottom"
                                                                                    className='e-tooltip-general'>
                                                                                    {parse(TOOL_TIP.invest_investment_popup)}
                                                                                </Tooltip>
                                                                            </div>
                                                                        }

                                                                    </p>

                                                                    <div className="d-flex justify-content-center mt-lg-4 mt-md-4 mt-0">
                                                                        {
                                                                            seconds === 0 ?
                                                                                <p className='color-primary-color e-poppins-regular e-font-14 line-height-14px cursor-pointer mb-0 e-forgot-pin position-relative w-max-content'
                                                                                    onClick={_resendOTP}>Resend OTP</p>
                                                                                :
                                                                                <p className='color-primary-color e-poppins-regular e-font-14 line-height-14px cursor-not-allowed mb-0 '>
                                                                                    Resend OTP in <span className="color-black">{seconds}s</span>
                                                                                </p>
                                                                        }

                                                                    </div>
                                                                </>
                                                            )
                                                        }

                                                    </div>
                                                </div>
                                            </Fragment>
                                            :
                                            <Fragment>
                                                {/* <Icon icon="close"
                                        size={24}
                                        data-bs-dismiss="modal"
                                        className={`position-absolute cursor-pointer e-modal-close-btn ${style.e_close}`} /> */}

                                                <button id="close-modal"
                                                    data-bs-dismiss="modal"
                                                    className="d-none"></button>
                                                <div className="text-center">
                                                    {/* <img src={require("../../Assets/Images/explore/confirm-payment.png")}
                                            alt="Confirm payment"
                                            width={72}
                                            height={72} /> */}
                                                    <p className="color-eerie-black e-poppins-medium e-font-18 mb-2 mt-3">Transaction initiated, awaiting payment from UPI app</p>

                                                    <p className="color-black e-font-28 e-inter-medium ">
                                                        <span className="e-font-14 vertical-sup">₹</span>
                                                        {props.totalAmount.toLocaleString('en-IN')}
                                                    </p>
                                                    <div className="d-flex justify-content-center">
                                                        <div className="bg-ghost-white border-radius-16px px-5 py-2 w-fit-content">
                                                            <p className="e-font-14 e-poppins-medium mb-0">Approve payment within</p>
                                                            <p className="e-font-24 e-poppins-regular mb-0">
                                                                {`${upiTimerSecond !== 600 ? '0' : ''}${Math.floor(upiTimerSecond / 60)} : ${upiTimerSecond % 60 < 10 ? '0' : ''}${upiTimerSecond % 60}`}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mt-4">
                                                    <h6 className="mb-3 ">UPI Payment Instructions</h6>
                                                    <ul className="ps-3">
                                                        <li className="e-font-14 e-poppins-regular color-outer-space mb-2">
                                                            Open your UPI app & confirm the payment via registered bank account with mt wealth.
                                                        </li>
                                                        <li className="e-font-14 e-poppins-regular color-outer-space  mb-2">
                                                            You may close this window after approving the payment in UPI app.
                                                        </li>
                                                    </ul>
                                                </div>
                                            </Fragment>
                                }
                            </>
                    }
                </div>
            </div>
        </div>
    )
}

export default CartInvestModal