/*
 *   File : cart.js
 *   Author URI : https://evoqins.com
 *   Description : Screen to show the items which are available for LUMPSUM purchase.
 *   Integrations : null
 *   Version : v1.1
 */

import { Fragment, useEffect, useState, memo } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Modal } from "bootstrap";
import { toast } from "react-toastify";

import { BreadCrumb } from "../../Component/BreadCrumb";
import { InvestmentHeader } from "../../Component/Header";
import Icon from "../../Component/Icon/icon";
import { GradientButton, OutlineButton } from "../../Component/CTA";
import { CartCard } from "../../Component/Card";
import Footer from "../../Component/Footer/footer";
import { CartInvestModal, DeleteCartModal, UpdateMfLumpusm, PaymentStatusModal } from "../../Component/Modal";
import { _mfDeleteCart, _mfGetCart } from "../../Helper/api";
import { CustomLoader, EmptyScreen } from "../../Component/Other";
import Store from "../../Store";
import APIService from "../../Service/api-service";

const PAGE_NAVIGATION = [
    { page: 'Home', url: '/' },
    { page: 'My cart', url: null },
];

export default memo(Cart);
function Cart(props) {

    const navigator = useNavigate();

    const CART_ITEMS = useSelector((state) => state.generalData.CART);
    const PROFILE_DATA = useSelector((state) => state.generalData.PROFILE_DATA);

    const [selectAll, setSelectedAll] = useState(true);
    const [selectedItems, setSelectedItems] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [expectedUnits, setExpectedUnits] = useState(0);
    const [showDeleteHover, setShowDeleteHover] = useState(false);
    const [showLumpsumModal, setShowLumpsumModal] = useState(null);
    const [loader, setLoader] = useState(true);
    const [itemsInCart, setItemsInCart] = useState([]);
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
    const [clearCart, setClearCart] = useState(false);
    const [showCartModal, setShowCartModal] = useState(false);
    const [bankDetails, setBankDetails] = useState([]);
    const [paymentStatus, setPaymentStatus] = useState(false);
    const [openPaymentStatusModal, setOpenPaymentStatusModal] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [paymentStatusText, setPaymentStatusText] = useState("");

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        _getCartData();
    }, []);

    // Updating price and unit when select any cart item;
    useEffect(() => {
        setTotalPrice(selectedItems.reduce((total, item) => total + parseFloat(item.amount), 0).toLocaleString('en-IN'));
        setExpectedUnits(selectedItems.reduce((total, item) => total + parseFloat(item.expected_units), 0).toFixed(2));
    }, [selectedItems]);

    useEffect(() => {
        console.log(bankDetails)
    }, [bankDetails]);

    useEffect(() => {
        console.log('cart item', CART_ITEMS);
        if (Object.keys(CART_ITEMS).length !== 0) {
            setSelectedItems(CART_ITEMS.data.sort((a, b) => a.name.localeCompare(b.name)));
            setItemsInCart(CART_ITEMS.data.sort((a, b) => a.name.localeCompare(b.name)));
            setBankDetails(CART_ITEMS.banks);
        }
    }, [CART_ITEMS])

    useEffect(() => {
        if (showDeleteConfirmationModal === true) {
            _openDeleteConfirmationModal();
        }
    }, [showDeleteConfirmationModal])

    useEffect(() => {
        if (showCartModal === true) {
            const modal = new Modal(document.getElementById("cart-invest"), {});
            modal.show();
        }
    }, [showCartModal])

    const _showCartModal = () => {
        setShowCartModal(true);
    }

    //to open confirm modal
    const _openDeleteConfirmationModal = () => {
        const modal = new Modal(document.getElementById("delete-cart"), {});
        modal.show();
    };

    // Update cart with new data;
    const _getCartData = async () => {
        setSelectedAll(true);
        try {
            const cart_data = await _mfGetCart();
            setItemsInCart(cart_data.data);
            Store.dispatch({
                type: 'ADD_TO_CART',
                payload: cart_data
            });
            setLoader(false);
        } catch (error) {
            setItemsInCart([]);
            Store.dispatch({
                type: 'ADD_TO_CART',
                payload: []
            });
            setLoader(false);
        }
    };


    // listener for opening lumpsum modal
    useEffect(() => {
        if (showLumpsumModal !== null) {
            _openLumpsumModal();
        }
    }, [showLumpsumModal]);


    useEffect(() => {
        _selectAllItems();
    }, [selectAll]);

    useEffect(() => {
        if (openPaymentStatusModal === true) {
            const modal = new Modal(document.getElementById("payment-status"), {});
            modal.show();
        }
    }, [openPaymentStatusModal])

    //to open funds modal
    const _openLumpsumModal = () => {
        const modal = new Modal(document.getElementById("lumpsum-amount"), {});
        modal.show();
    };

    const _handleEditAmount = (item) => {
        let current_nav = item.nav
        let local_item = { ...item };
        let new_item = {
            "current_nav": current_nav,
            "nav_change": item.nav_change,
            "nav_change_percentage": item.nav_change_percentage,
            "nav_date": item.nav_date
        };
        local_item.nav = new_item;
        setShowLumpsumModal(local_item);
    }

    const _handleSelectAll = () => {
        setSelectedAll(!selectAll);
    }

    // Function to toggle item selection
    const _toggleItemSelection = (selected_object) => {
        // Create a copy of the current set to avoid mutating state directly
        const updated_selected_items = [...selectedItems];

        // getting index
        const selected_index = updated_selected_items.findIndex((item) => {
            return selected_object.id === item.id;
        });

        if (selected_index > -1) {
            // Item is already selected, so remove it
            updated_selected_items.splice(selected_index, 1);
            setSelectedAll(false);
        } else {
            // Item is not selected, so add it
            updated_selected_items.push(selected_object);
        }
        // Update the state with the new set of selected item IDs
        setSelectedItems(updated_selected_items);
    };


    // Function to select all items or clear selection
    const _selectAllItems = () => {
        if (selectAll === false) {
            // reset selected cart items to empty if all items selected
            if (selectedItems.length === itemsInCart.length) {
                setSelectedItems([]);
            }
        } else {
            // Create a set with all item IDs and update the state
            setSelectedItems(itemsInCart);
        }
    };

    const _handleDelete = () => {
        setShowDeleteConfirmationModal(true);
    };

    const _clearCart = () => {
        setClearCart(true);
        setShowDeleteConfirmationModal(true);
    }

    //update the cart with deleted items and remove selected items from the cart
    const _confirmDelete = async () => {

        setLoader(true);
        const updated_cart = itemsInCart
            .filter((cart_item) => {
                return selectedItems.some((selected_item) => cart_item.id === selected_item.id);
            })
            .map((cart_item) => cart_item.mf_id);
        let result = await _deleteItems(updated_cart);


    }

    const _handleClearCart = () => {
        const ids = itemsInCart.map(item => item.mf_id);
        _deleteItems(ids, true);
    }

    const _handleShowDeleteHover = () => {
        setShowDeleteHover(!showDeleteHover)
    }

    // update redux with new amount value
    const _handleUpdateAmount = (data) => {
        Store.dispatch({
            type: 'ADD_TO_CART',
            payload: data
        });
        setShowLumpsumModal(null);

    }

    function _redirectToExplore() {
        navigator('/explore');
    }

    const _handleCloseModal = () => {
        setShowDeleteConfirmationModal(false);
        setClearCart(false);
    }

    const _navigateToLanding = (path) => {
        // let url = process.env.REACT_APP_LANDING_PAGE_URL + path;
        window.open(path, "_blank", "noreferrer");
    }

    // API - Delete items in cart
    const _deleteItems = async (updated_cart, delete_all = false) => {
        setButtonLoader(true);
        let result = true;
        _mfDeleteCart(updated_cart)
            .then((response) => {
                window.scrollTo({ top: 0, behavior: 'smooth' });
                Store.dispatch({
                    type: 'ADD_TO_CART',
                    payload: response
                });
                if (delete_all === true) {
                    toast.success("Mutual funds removed from cart successfully", {
                        type: "success",
                    });
                } else if (updated_cart.length === 1) {
                    const index = itemsInCart.findIndex(item => item.mf_id === updated_cart[0])
                    toast.success(`${itemsInCart[index].name} has been removed from the cart.`, {
                        type: "success",
                    });
                } else {
                    toast.success("Mutual funds removed from cart successfully", {
                        type: "success",
                    });
                }
                setLoader(false);
            }).catch((response) => {
                toast.error(response, {
                    type: "error",
                });
                result = false;
                setLoader(false);
            });
        setButtonLoader(false);
        return result;
    }

    // Checking user have bank account before cart checkout
    function _validateBanks() {
        if (PROFILE_DATA.kyc_step < 11) {
            toast.dismiss();
            toast.error("Complete KYC to start investing", {
                type: 'error'
            });
            return;
        }
        if (CART_ITEMS.banks.length === 0) {
            toast.error('Please add a bank account before proceeding with the cart checkout', {
                type: 'error'
            })
        } else {
            _showCartModal();
        }
    }

    // API:: get payment status after investment 
    function _getPaymentStatus(id, mode_of_payment) {
        let url = 'payment/check-status';
        let payload = JSON.stringify({
            payment_id: id
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                if (mode_of_payment === 2) {
                    setPaymentStatus(false);
                    setPaymentStatusText('Processing');
                }
                else {
                    setPaymentStatus(response.data.payment_status);
                    setPaymentStatusText(response.data.payment_status_txt);
                }
                setOpenPaymentStatusModal(true);
            }else{
                toast.dismiss();
                toast.error(response.message,{
                    type: 'error'
                })
            }
        })
    }

    function _handleStatusModalClose() {
        setOpenPaymentStatusModal(false);
        _getCartData();
    }

    return (
        <Fragment>

            <div className="e-cart e-page-container margin-72px-bottom pb-sm-0 pb-4">
                {/* Header */}
                <InvestmentHeader />

                <BreadCrumb data={PAGE_NAVIGATION} />

                {
                    loader === true ?
                        <div className="h-80vh">
                            <CustomLoader />
                        </div>
                        :
                        <>
                            {
                                itemsInCart.length == 0 ?
                                    <div className="col-12 text-center mt-4 h-80vh align-items-middle d-flex justify-content-center">
                                        <EmptyScreen image={require('../../Assets/Images/nodata/order.svg').default}
                                            title="Start investing now"
                                            description={'Add funds from Explore and start your investment journey now'}
                                            buttonLabel='Explore fund'
                                            onPress={() => navigator('/explore')} />
                                    </div>
                                    :
                                    <>
                                        <p className="color-black e-poppins-medium e-font-20-sm-18 line-height-32px my-sm-4 my-3">My cart ({itemsInCart.length})</p>
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className="col-lg-8 col-md-8">
                                                    {
                                                        itemsInCart.length !== 0 && (
                                                            <div className="d-flex justify-content-between">
                                                                <div className="d-flex align-items-center pt-2 pb-3 cursor-pointer"
                                                                    onClick={_handleSelectAll}>
                                                                    <Icon icon={selectAll === true ? "cart-selected" : "cart-unselected"}
                                                                        size={24} />
                                                                    <p className="color-black e-poppins-medium e-font-16 line-height-16px mb-0 ms-1">Select all</p>
                                                                </div>
                                                                {
                                                                    selectedItems.length !== 0 &&
                                                                    <div className="position-relative"
                                                                        onMouseEnter={_handleShowDeleteHover}
                                                                        onMouseLeave={_handleShowDeleteHover}>
                                                                        <Icon icon={showDeleteHover === true ? "delete-red" : "delete"}
                                                                            size={24}
                                                                            className="cursor-pointer"
                                                                            onClick={_handleDelete} />
                                                                        {
                                                                            showDeleteHover === true &&
                                                                            <p className="position-absolute border-radius-4px bg-body-color color-outer-space e-poppins-regular e-font-12 line-height-12px mb-0 p-2 e-cart-delete-hover">Delete selected</p>
                                                                        }
                                                                    </div>

                                                                }
                                                            </div>
                                                        )
                                                    }
                                                    <div className="d-flex flex-column gap-16px">
                                                        {
                                                            itemsInCart.map((item) =>
                                                                <CartCard data={item}
                                                                    selectedItems={selectedItems}
                                                                    key={item.id}
                                                                    index={item.id}
                                                                    toggleItem={_toggleItemSelection}
                                                                    editAmount={_handleEditAmount} />
                                                            )
                                                        }
                                                    </div>
                                                    {
                                                        itemsInCart.length !== 0 ? (
                                                            <p className="color-jett-black e-poppins-regular e-font-14 line-height-22px mt-3 mb-4">
                                                                *The expected units is based on last declared NAV.
                                                            </p>
                                                        ) : (
                                                            <p className="color-jett-black e-poppins-regular e-font-14 line-height-14px mt-3 mb-5 text-center">
                                                                Your cart is currently empty. Please consider adding more items to your cart.
                                                            </p>
                                                        )
                                                    }

                                                    <div className="d-flex justify-content-center gap-16px w-100">
                                                        {
                                                            itemsInCart.length !== 0 && (
                                                                <OutlineButton label="Clear cart"
                                                                    className="px-3 padding-12px-tb e-font-16"
                                                                    handleClick={_clearCart} />
                                                            )
                                                        }

                                                        <GradientButton label="Add more funds"
                                                            handleClick={_redirectToExplore}
                                                            className="px-3 padding-12px-tb e-font-16" />
                                                    </div>
                                                </div>
                                                {
                                                    itemsInCart.length !== 0 && (
                                                        <div className="col-lg-4 col-md-4 mt-lg-0 mt-4">
                                                            <div className="border-radius-16px border-all border-bright-gray bg-cosmic-latte p-4 pt-3 text-center">
                                                                <img src={require("../../Assets/Images/cart/total-payable.png")}
                                                                    alt="Total payable"
                                                                    width={88}
                                                                    height={88} />
                                                                <p className="color-outer-space e-poppins-semi-bold e-font-24 line-height-20px mb-1 mt-3"><span className="e-inter-semi-bold">₹ </span>{totalPrice}</p>
                                                                <p className="color-black e-poppins-regular e-font-16 line-height-16px margin-32px-bottom-sm-16">Total amount payable</p>
                                                                <p className="color-outer-space e-poppins-regular e-font-16 line-height-16px mb-sm-2 mb-0">Number of selected funds: {selectedItems.length}</p>
                                                                <p className="color-outer-space e-poppins-regular e-font-16 line-height-16px margin-28px-bottom mt-sm-0 mt-3">Expected order units* : {expectedUnits}</p>
                                                                <p className="color-outer-space e-poppins-regular e-font-12 mb-2">I agree with the <span className="color-primary-color cursor-pointer" onClick={() => _navigateToLanding(`${process.env.REACT_APP_LANDING_PAGE_URL}/disclaimer`)}>Disclaimers</span> and <span className="color-primary-color cursor-pointer" onClick={() => _navigateToLanding(`${process.env.REACT_APP_LANDING_PAGE_URL}/terms`)}>T&C</span> of Mastertrust</p>
                                                                <GradientButton label="Checkout now"
                                                                    className="padding-12px-tb e-font-16 w-100 d-flex justify-content-center"
                                                                    disabled={selectedItems.length === 0 ? true : false}
                                                                    handleClick={_validateBanks} />

                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </>
                            }
                        </>
                }
            </div>

            {/* Footer */}
            <Footer />

            {
                showLumpsumModal !== null ?
                    <UpdateMfLumpusm data={showLumpsumModal}
                        updateFund={false}
                        close={() => setShowLumpsumModal(null)}
                        handleUpdateAmount={_handleUpdateAmount} />
                    :
                    null
            }

            {
                showDeleteConfirmationModal === true ?
                    <DeleteCartModal confirmDelete={clearCart === true ? _handleClearCart : _confirmDelete}
                        selectedItem={selectedItems}
                        singleItem={selectedItems.length === itemsInCart.length ? null : selectedItems.length === 1 ? true : false}
                        close={_handleCloseModal}
                        loading={buttonLoader} />
                    :
                    null
            }

            {
                showCartModal === true ?
                    <CartInvestModal selectedItems={selectedItems}
                        bankDetails={bankDetails}
                        totalAmount={totalPrice}
                        refreshCart={_getCartData}
                        getPaymentStatus={_getPaymentStatus}
                        close={() => setShowCartModal(false)} />
                    :
                    null
            }

            {
                openPaymentStatusModal === true && (
                    <PaymentStatusModal status={paymentStatus}
                        paymentStatus={paymentStatusText}
                        type={1}
                        close={_handleStatusModalClose} />
                )
            }
        </Fragment>
    )
}