
/*
 *   File : empty-screen.js
 *   Author URI : https://evoqins.com
 *   Description : no data screenn
 *   Integrations : null
 *   Version : v1.1
 */
import { GradientButton, OutlineButton } from '../CTA';

export default function EmptyScreen(props) {

    return (
        <div className={`pt-lg-5 d-flex justify-content-center align-items-center ${props.marginClass ? props.marginClass : 'mt-5'}`}>
            <div className='text-center'>
                <img src={props.image}
                    alt="404 page"
                    draggable={false}
                    className={props.imageClass} />
                <h5 className='e-poppins-semi-bold e-font-18  color-outer-space d-block mt-3'>
                    {props.title}
                </h5>
                {/* <h5 className='e-poppins-semi-bold e-font-18 line-height-26px color-outer-space d-block'>
                    {props.description}
                </h5> */}
                {
                    props.buttonLabel && (
                        <>
                            {
                                props.secondary === true ?
                                    <OutlineButton label={props.buttonLabel}
                                        disabled={props.disabled}
                                        className='padding-12px-tb padding-24px-lr mt-4'
                                        handleClick={props.onPress} />
                                    :
                                    <GradientButton label={props.buttonLabel}
                                        disabled={props.disabled}
                                        className='padding-12px-tb padding-24px-lr mt-4'
                                        handleClick={props.onPress} />
                            }
                        </>

                    )
                }

            </div>

        </div>

    )
};

EmptyScreen.defaultProps = {
    image: require('../../Assets/Images/nodata/general.svg').default,
    title: "No data found at moment",
    disabled: false,
    description: 'Explore more and please wait for data to populate.',
    onPress: () => { }
}


