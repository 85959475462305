/*
 *   File : invest.js
 *   Author URI : https://evoqins.com
 *   Description : Invest in goal and MF
 *   Integrations : null
 *   Version : v1.1,
 *   PropsAccepting : investmentType: 1 is for fund investment, 2 is for goal investment
 *                    close : type () callback modal close
 */

import { Fragment, useEffect, useRef, useState, memo, useContext } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import parse from 'html-react-parser';
import { format } from "date-fns";
import { Tooltip } from "react-tooltip";

import Icon from "../Icon/icon";
import { CustomTab } from "../Tab";
import { CustomAmountInput, OTPInput, RadioGroup, CustomTextInput, DatePicker } from "../Form-elements";
import SIPDateModal from "./sip-date";
import { Amountchip } from "../Other";
import { GradientButton } from "../CTA";
import { _convertToIdLabelArray, useClickOutside, useKeyHandler, _getColorForInvestmentStyle } from "../../Helper";
import APIService from "../../Service/api-service";
import { _getMFInvestmentSummary, _getGoalInvestmentSummary, _createMandate, _createPhysicalMandate, _authenticateMandate, _mandateStatus } from "../../Helper/api";
import { _consent2FA } from '../../Helper/api';
import { _getDayOfMonth } from "../../Helper";
import { DarkModeContext } from "../../Context/dark-mode-context";
import style from "../../Styles/Component/invest-goal.module.scss"
import Color from "../../Styles/color.module.scss"

const TAB_DATA = ['SIP', 'One-Time'];

const FIRST_INSTALLMENT = [
    { id: 1, label: 'Yes' },
    { id: 2, label: 'No' },
];

const PAYMENT_TYPE = [
    { id: 2, label: 'Net banking' },
    { id: 1, label: 'UPI' },
    { id: 3, label: 'NEFT/RTGS' }
]
const NEW_FOLIO = { id: null, label: "Create new folio" };

const AMOUNT_SUGGESTIONS = [
    100000,
    200000,
    300000,
    400000,
    500000
];

const MANDATE_TYPE = [
    { id: 1, label: 'E-mandate' },
    { id: 2, label: 'Physical mandate' }
]

export default memo(InvestGoalModal);


const today = new Date();
const min_date = new Date(today);
min_date.setFullYear(min_date.getFullYear() + 1);

const max_date = new Date(today);
max_date.setFullYear(max_date.getFullYear() + 40);
max_date.setMonth(max_date.getMonth() - 1);

const default_date = new Date(today);
default_date.setFullYear(default_date.getFullYear() + 5);

const start_date = new Date(today); // Initialize with the provided 'today' date
start_date.setMonth(start_date.getMonth() + 1);

const end_date = new Date(start_date);
end_date.setMonth(end_date.getMonth() + 1);

function InvestGoalModal(props) {

    const { darkMode } = useContext(DarkModeContext);
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    const navigator = useState();

    var timer;  // payment url status checking timer reason for make this as global is for clearing interval incase user close the modal;
    const modalRef = useRef(null);
    const mandateRef = useRef();
    const FolioRef = useRef();
    const sipInputRef = useRef(null);
    const lumpusmInputRef = useRef(null);
    const paymentInputRef = useRef(null);
    const modalBodyRef = useRef(null);

    const TOOL_TIP = useSelector(state => state.generalData.TOOL_TIP_DATA);
    const PROFILE_DATA = useSelector(state => state.generalData.PROFILE_DATA);

    const [tabIndex, setTabIndex] = useState(1);
    const [amount, setAmount] = useState("");
    const [firstInvestment, setFirstInvestment] = useState(1);
    const [selectBank, setSelectBank] = useState(false);
    const [selectedBank, setSelectedBank] = useState({});
    const [selectMandate, setSelectMandate] = useState(false);
    const [lumpsumAmount, setLumpsumAmount] = useState("");
    const [selectOneTimeBank, setSelectOneTimeBank] = useState(false);
    const [selectedOneTimeBank, setSelectedOneTimeBank] = useState({});
    const [showMessage, setShowMessage] = useState(false);
    const [step, setStep] = useState(1);
    const [pinString, setPinString] = useState("");
    const [showFolioDropDown, setShowFolioDropDown] = useState(false);
    const [selectedFolio, setSelectedFolio] = useState(NEW_FOLIO);
    const [loader, setLoader] = useState(true);
    const [selectedMandate, setSelectedMandate] = useState({});
    const [amountError, setAmountError] = useState("");
    const [lumpsumError, setLumpsumError] = useState("");
    const [date, setDate] = useState("");
    const [mfPropsData, setMfPropsData] = useState({});
    const [pinError, setPinError] = useState("");
    const [foliosData, setFoliosData] = useState([NEW_FOLIO]);
    const [apiLoader, setApiLoader] = useState(false);
    const [goalId, setGoalId] = useState(null); // id to be used for getting teh goal details
    const [goalTypeId, setGoalTypeId] = useState(null); // id to be used to invest
    const [goalInvestmentData, setGoalInvestmentData] = useState({});
    const [suggestedSIPAmounts, setSuggestedSIPAmounts] = useState([]);
    const [suggestedLumpsumAmounts, setSuggestedLumpsumAmounts] = useState([]);
    const [enabledSIPDates, setEnabledSIPDates] = useState([]);
    const [seconds, setSeconds] = useState(30);
    const [tabData, setTabData] = useState(TAB_DATA);
    const [mfOrderId, setMfOrderId] = useState(null);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(2); // 1 -> UPI, 2 -> Net banking 
    const [upiId, setUpiId] = useState('');
    const [upiIdError, setUpiIdError] = useState('');
    const [mandateAmountError, setMandateAmountError] = useState("");
    const [mandateAmount, setMandateAmount] = useState("");
    const [showCreateNewMandate, setShowCreateNewMandate] = useState(false);
    const [upiTimerSecond, setUpiTimerSecond] = useState(600);
    const [paymentId, setPaymentId] = useState(null);
    const [expiryDate, setExpiryDate] = useState(format(max_date, "dd-MM-yyyy"));
    const [expiryDateError, setExpiryDateError] = useState("");
    const [paymentOtpRequired, setPaymentOtpRequired] = useState(true);
    const [sipEndDate, setSipEndDate] = useState("");
    const [sipEndDateMinDate, setSipEndDateMinDate] = useState(null);
    const [skipPayment, setSkipPayment] = useState(false);
    const [mandateType, setMandateType] = useState(1);
    const [startDate, setStartDate] = useState(format(start_date, "dd-MM-yyyy"));
    const [frequencyList, setFrequencyList] = useState([]);
    const [selectedFrequency, setSelectedFrequency] = useState("");
    const [activeSIPAmount, setActiveSIPAmount] = useState(null);
    const [activeLumpsumAmount, setActiveLumpsumAmount] = useState(null);
    const [activeMandateChip, setActiveMandateChip] = useState(null);
    const [utrNumber, setUtrNumber] = useState("");
    const [utrNumberError, setUtrNumberError] = useState("");
    const [openUTRInstruction, setOpenUTRInstruction] = useState(false);
    const [showUTRInstructions, setShowUTRInstructions] = useState(false);

    useEffect(() => {
        if (date === '') {
            const { date } = _getDayOfMonth(enabledSIPDates);
            setDate(`${date}`);
        }
        var my_modal = document.getElementById("invest-goal");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
            clearInterval(timer);
            setStep(1);
            setPinString("");
        });
    }, [enabledSIPDates]);

    useEffect(() => {
        if (date) {
            console.log('dataaa', date);
            const current_date = new Date();
            let month = current_date.getMonth() + 1;
            current_date.setMonth(month);
            current_date.setDate(parseInt(date));
            setSipEndDateMinDate(current_date);
            const sip_default_date = new Date(current_date);
            sip_default_date.setFullYear(sip_default_date.getFullYear() + 2);
            setSipEndDate(format(sip_default_date, "dd-MM-yyyy"));
        }
    }, [date]);

    useEffect(() => {
        if (step === 3) {
            _checkUPIStatus();
            const interval = setInterval(() => {
                setUpiTimerSecond(prevSeconds => {
                    if (prevSeconds === 0) {
                        clearInterval(interval);
                        let close_button = document.getElementById("close-modal");
                        close_button.click();
                        props.getPaymentStatus(paymentId, firstInvestment === 2 && true, selectedPaymentMethod);
                    }
                    return prevSeconds - 1;
                });
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [step]);



    useEffect(() => {
        if (step === 2) {
            const countdown = setInterval(() => {
                if (seconds > 0) {
                    setSeconds(seconds - 1);
                } else {
                    clearInterval(countdown);
                    // Handle expiration logic here (e.g., show a resend button)
                }
            }, 1000);

            return () => {
                clearInterval(countdown);
            };
        } else {
            setSeconds(30);
        }
    }, [seconds, step]);

    // showing investment type based button click (Start sip, invest now)
    useEffect(() => {
        if (props.selectedTab !== null || props.selectedTab !== undefined) {
            setTabIndex(props.selectedTab);
        }
    }, [props.selectedTab]);

    useEffect(() => {
        if (props.investmentType === 1) {
            console.log('props.data::::', props.data);
            if (props.data.lumpsum_enabled === true && props.data.sip_enabled === false) {
                setTabData(['One-Time']);
                setTabIndex(2);
            }
            if (props.data.lumpsum_enabled === false && props.data.sip_enabled === true) {
                setTabData(['SIP']);
            }
            if (props.data.sip_chips !== null) {
                setSuggestedSIPAmounts(props.data.sip_chips);
            }
            if (props.data.lumpsum_chips !== null) {
                setSuggestedLumpsumAmounts(props.data.lumpsum_chips);
            }
            if (props.data.sip_dates) {
                setEnabledSIPDates(props.data.sip_dates); // Set allowed SIP dates for MF investment
            }

        }
    }, [props.data, props.investmentType]);

    useEffect(() => {
        if (props.fundType === "SIP") {
            setAmount(props.amount);
        }
        if (props.fundType === "Lumpsum") {
            setLumpsumAmount(props.amount);
        }
    }, [props.amount, props.fundType]);

    useEffect(() => {
        if (props.investmentType === 2) {
            setSelectBank(true);
        }
    }, [props.investmentType])

    useEffect(() => {
        setGoalId(props.goalId);
    }, [props.goalId]);

    useEffect(() => {
        if (goalId) {
            console.log('goalId', goalId);
            _fetchGoalDetail();
        }
    }, [goalId, date, firstInvestment]);

    useEffect(() => {
        if (mandateAmountError !== "") {
            var element = document.getElementById('mandate-amount');
            console.log('element', element);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [mandateAmountError]);

    useEffect(() => {
        if (Object.keys(mfPropsData).length !== 0) {
            console.log('ifyr');
            let linked_banks = mfPropsData.banks;
            const selected_bank = tabIndex === 1 ? selectedBank : selectedOneTimeBank;
            // cob
            // get the default bank and mandates
            if (Object.keys(selected_bank).length === 0) {
                console.log('inside');
                const default_bank_index = linked_banks.findIndex(bank => bank.primary === true);
                if (default_bank_index !== -1) {
                    let default_bank = linked_banks[default_bank_index];
                    if (default_bank !== undefined) {
                        setSelectedBank(default_bank);
                        setSelectedOneTimeBank(default_bank);
                        let selected_bank_mandates = default_bank.mandates;
                        console.log('selected_bank_mandates', selected_bank_mandates);
                        if (selected_bank_mandates.length !== 0) {
                            if (Object.keys(selectedMandate).length === 0 || !selected_bank_mandates.some(item => item.id === selectedMandate.id)) {
                                setSelectedMandate(selected_bank_mandates[0]);
                            }
                        } else {
                            setShowCreateNewMandate(true);
                            setSelectBank(true);
                        }

                    }
                }
            } else {
                const default_bank_index = linked_banks.findIndex(bank => bank.bank_id === selected_bank.bank_id);
                console.log('default ban', default_bank_index);
                if (default_bank_index !== -1) {
                    let default_bank = linked_banks[default_bank_index];
                    console.log('not ndere', default_bank);
                    if (default_bank !== undefined) {
                        setSelectedBank(default_bank);
                        setSelectedOneTimeBank(default_bank);
                        let selected_bank_mandates = default_bank.mandates;
                        if (selected_bank_mandates.length !== 0) {
                            setShowCreateNewMandate(false);
                            if (!selected_bank_mandates.some(item => item.id === selectedMandate.id)) {
                                setSelectedMandate(selected_bank_mandates[0]);
                            }
                        }
                        else {
                            setShowCreateNewMandate(true);
                            setSelectBank(true);
                        }

                    }
                }
            }


            // get and format already invested folios
            if (mfPropsData.folios && mfPropsData.folios.length !== 0) {
                let converted_folios = _convertToIdLabelArray(mfPropsData.folios);
                let available_folios = [NEW_FOLIO, ...converted_folios];
                setFoliosData(props.isPortfolio === true ? converted_folios : available_folios);
                // setSelectedFolio(converted_folios.length > 1 ? converted_folios[1] : converted_folios[0]); // select second item from the list
            } else {
                setSelectedFolio(foliosData[0]);
            }
            setLoader(false);
            console.log('State change');
        }
    }, [mfPropsData]);

    useEffect(() => {
        setMfPropsData(props.mfInvestmentData);

        if (props.mfInvestmentData?.frequency_list?.length > 0) {
            setFrequencyList(props.mfInvestmentData.frequency_list);
            setSelectedFrequency(props.mfInvestmentData.frequency_list[0]);
        }
    }, [props.mfInvestmentData]);

    useClickOutside(mandateRef, () => {
        setSelectMandate(false);
    });

    useClickOutside(FolioRef, () => {
        setShowFolioDropDown(false);
    });

    // avoid selection to hide pin
    useKeyHandler('invest-goal', 'a', (event) => {
        if (event.ctrlKey || event.metaKey) {
            event.preventDefault();
        }
    });

    // handler for tab change
    const _handleTabSelect = (index) => {

        setTabIndex(index);
        setAmountError(''); // clear any error
    }

    function _handleDateChange(value) {
        setExpiryDate(value);
        setExpiryDateError("");
    }

    function _openUTRInstructionModal() {
        setShowUTRInstructions(true);
    }

    function _handleStartDate(value) {
        setStartDate(value);
    }

    function _handleSIPEndDate(value) {
        setSipEndDate(value);
    }
    function _handleDatePickerFocus() {
        let element = document.getElementById('footer-div');
        setTimeout(() => {
            element.scrollIntoView({ behavior: 'smooth' })
        }, 200);

    }

    function _handleSIPEndDateFocus() {
        let element = document.getElementById('sip-end-date');
        setTimeout(() => {
            element.scrollIntoView({ behavior: 'smooth' })
        }, 200);

    }

    function _handleMandateType(value) {
        setMandateType(value);
    }

    function _closeUTRInstruction() {
        setShowUTRInstructions(false);
    }

    // update input value
    const _handleInputChange = (type, value) => {
        switch (type) {
            case 1:
                setAmount(value);
                setAmountError("");
                break;
            case 2:
                setLumpsumAmount(value);
                setLumpsumError("");
                break;
            case 3:
                setUpiId(value);
                setUpiIdError("");
                break;
            case 4:
                setUtrNumber(value);
                setUtrNumberError("");
                break;
        }
    }

    // update pre-defined SIP amount 
    const _handleChipSelect = (type, index) => {
        switch (type) {
            case 1:
                setAmount(suggestedSIPAmounts[index])
                setAmountError("");
                setActiveSIPAmount(index);
                break;
            case 2:
                setLumpsumAmount(suggestedLumpsumAmounts[index])
                setLumpsumError("");
                setActiveLumpsumAmount(index);
                break;
        }
    }

    // handler for showing banks
    const _handleSelectBank = (type) => {
        switch (type) {
            case 1:
                setSelectBank(!selectBank)
                break;
            case 2:
                setSelectOneTimeBank(!selectOneTimeBank)
                break;
        }
        // setShowCreateNewMandate(false);
    }

    // handler for showing mandate
    const _handleSelectMandate = () => {
        setSelectMandate(!selectMandate);
        if (modalBodyRef.current) {
            modalBodyRef.current.scrollTop = modalBodyRef.current.scrollHeight - 20;
        }
    }

    const _handleSelectedBank = (data, type) => {
        if (data.mandates[0] === undefined) {
            setShowCreateNewMandate(true);

        } else {
            setShowCreateNewMandate(false);
        }
        setMandateAmount("");
        switch (type) {
            case 1:
                setSelectedBank(data)
                setSelectedMandate(data.mandates[0]);

                break;
            case 2:
                setSelectedOneTimeBank(data);
                setSelectedMandate(data.mandates[0]);

                break;
            default:
                break;
        }
        if (props.investmentType === 2) {
            _fetchGoalDetail()
        } else {
            _fetchMfInvestmentSummary(props.data.id, firstInvestment === 1 ? true : false, date, data.mandates[0], selectedFrequency);
        }

    }

    const _handleSelectedMandate = (item) => {
        setSelectedMandate(item);
        setShowCreateNewMandate(false);
        if (props.investmentType === 2) {
            _fetchGoalDetail()
        } else {
            _fetchMfInvestmentSummary(props.data.id, firstInvestment === 1 ? true : false, date, item.id, selectedFrequency);
        }
    }

    const _handleShowMessage = () => {
        setShowMessage(true);
    }

    const _handleHideMessage = () => {
        setShowMessage(false);
    }

    const _handleGoback = () => {
        setPinString("");
        setPinError("");
        setStep(1);
    }

    const _handlePin = (value) => {
        setPinString(value);
        setPinError("");
    }

    const _handleFolioClick = () => {
        setShowFolioDropDown(!showFolioDropDown);
    }

    const _handleFolioSelect = (data) => {
        setSelectedFolio(data)
    }

    // validate pin
    const _validatePin = () => {
        let valid = true;
        if (paymentOtpRequired === true) {
            if (pinString.length === 0) {
                setPinError("OTP Required");
                valid = false;
            }
        }
        if (selectedPaymentMethod === 1) {
            if (upiId.length === 0) {
                paymentInputRef.current.focus();
                setUpiIdError("UPI Id can't be empty");
                valid = false;
            }

            if (upiId.includes("@") === false) {
                paymentInputRef.current.focus();
                setUpiIdError("Invalid UPI Id");
                valid = false;

            }
        }
        if (selectedPaymentMethod === 3) {
            if (utrNumber === "") {
                setUtrNumberError("UTR number can't be empty");
                valid = false;
            }
        }
        if (valid === true) {
            if (props.investmentType === 1) {
                _mfInvest();
            } else {
                _goalInvest();
            }
        }
    }

    // date selected form SIP dates. It will be always between 1-28.
    const _handleDateSelected = (date) => {
        setDate(date.toString());
        if (props.investmentType === 1) {
            _fetchMfInvestmentSummary(props.data.id, firstInvestment === 1 ? true : false, date, selectedMandate?.id, selectedFrequency)
        }
    }

    // Option to check whether the user is making the first instalment today 
    const _handleRadioInput = (instalment_id) => {
        setFirstInvestment(instalment_id);
        if (props.investmentType === 1) {
            _fetchMfInvestmentSummary(props.data.id, instalment_id === 1 ? true : false, date, selectedMandate?.id, selectedFrequency)
        }
    }

    const _handleFrequency = (value) => {
        setSelectedFrequency(value);
        _fetchMfInvestmentSummary(props.data.id, firstInvestment === 1 ? true : false, date, selectedMandate?.id, value);
    }

    const _handlePaymentRadio = (id) => {
        setSelectedPaymentMethod(id);
    }
    // UI validation for MF data 
    const _validateMFData = () => {
        if (tabIndex === 1) {
            if (amount.length === 0) {
                sipInputRef.current.focus();
                setAmountError("Amount can't be empty");
                return;
            }
            if (amount === 0) {
                sipInputRef.current.focus();
                setAmountError("Amount can't be 0");
                return;
            }
            // if (selectedPaymentMethod === 1) {
            //     if (upiId.length === 0) {
            //         paymentInputRef.current.focus();
            //         setUpiIdError("UPI Id can't be empty");
            //         return;
            //     }

            //     if (upiId.includes("@") === false) {
            //         paymentInputRef.current.focus();
            //         setUpiIdError("Invalid UPI Id");
            //         return;
            //     }
            // }
        }
        if (tabIndex === 2) {
            if (lumpsumAmount.length === 0) {
                lumpusmInputRef.current.focus();
                setLumpsumError("Amount can't be empty");
                return;
            }
            if (lumpsumAmount === 0) {
                lumpusmInputRef.current.focus();
                setLumpsumError("Amount can't be 0");
                return;
            }


        }
        _validateMFInvestment();
    }

    // Handle resend otp 
    function _handleResendOTP() {
        setSeconds(30);
        setPinString("");
        if (props.investmentType === 1) {
            // _validateMFInvestment();
            _mfResendOTP();
        } else {
            // _validateGoalInvestment()
            _goalResendOTP();
        }
    }

    function _mfResendOTP() {
        _consent2FA("MF")
            .then((response) => {
                toast.dismiss();
                toast.success("An OTP has been sent to your registered phone number", {
                    type: "success",
                });
            }).catch((response) => {
                toast.dismiss();
                toast.error(response, {
                    type: "error"
                });
            });
    }

    function _goalResendOTP() {
        _consent2FA("GOAL")
            .then((response) => {
                toast.dismiss();
                toast.success("An OTP has been sent to your registered phone number", {
                    type: "success",
                });
            }).catch((response) => {
                toast.dismiss();
                toast.error(response, {
                    type: "error"
                });
            });
    }

    // API - get mf investment data
    const _fetchMfInvestmentSummary = (id, isFirstInvestment, date, mandate_id = selectedMandate?.id, frequency) => {
        // console.log('frequency', frequency);
        return _getMFInvestmentSummary(id, isFirstInvestment, date, selectedMandate?.id, frequency)
            .then((response) => {
                setMfPropsData(response);
                // console.log('response', response.data.frequency_list);
                if (response.data?.frequency_list) {
                    setFrequencyList(response.data.frequency_list);
                    if (selectedFrequency == "" && response.data.frequency_list.length > 0) {
                        setSelectedFrequency(response.data.frequency_list[0]);
                    }
                }

            })
            .catch((error) => {
                toast.dismiss();
                toast.error(error, {
                    type: "error",
                });
            });
    };


    function _validateMandate() {
        let valid = true;
        if (Object.keys(selectedMandate).length === 0) {
            valid = false;
        }
        return valid;
    }

    const _closeModal = () => {
        setApiLoader(false);
    }

    function _checkInvestmentAmountIsZero(amount, type) {
        let final_value = null;
        if (type === 1) {
            if (amount !== 0) {
                final_value = {
                    amount: amount,
                    bank_id: selectedBank.bank_id
                }
            }
        } else {
            if (amount !== 0) {
                final_value = {
                    amount: amount,
                    date: date,
                    step_sip: 0,
                    // generate_today: firstInvestment === 1 ? true : false,
                    mandate_id: selectedMandate.id
                }
            }
        }
        return final_value;
    }


    const _navigateToLanding = (path) => {
        // let url = process.env.REACT_APP_LANDING_PAGE_URL + path;
        window.open(path, "_blank", "noreferrer");
    }

    // Function for open payment url from invest api;
    function _openPaymentURL(url, html, payment_id, type = 1) {

        var new_window;

        // In production we expecting string of html and convert given string as html and opening in new window
        // In other env we expecting url and directly open it in new window
        if (process.env.REACT_APP_ENV == 'PROD') {
            const winUrl = URL.createObjectURL(
                new Blob([html], { type: "text/html" })
            );
            new_window = window.open(
                winUrl,
                "win",
                `width=800,height=800,screenX=200,screenY=200`
            );
        } else {
            new_window = window.open(url, '_blank', 'width=800,height=800');
        }

        if (new_window !== null) {
            new_window.opener = null;
            timer = setInterval(function () {
                if (new_window.closed) {
                    clearInterval(timer);
                    _paymentCallback(payment_id);
                }
            }, 1000);
        } else {
            setApiLoader(false);
            toast.dismiss();
            toast.error('Popups are disabled in your browser. Please allow popups to open the payment gateway.', {
                type: 'error'
            });
        }

    }

    // Open url for authorizing mandate
    function _openMandateURL(url, mandate_id) {
        var auth_window = null;
        if (!url.startsWith("https://")) {
            url = "https://" + url;
        }

        // In production we expecting string of html and convert given string as html and opening in new window
        // In other env we expecting url and directly open it in new window
        // if (process.env.REACT_APP_ENV == 'PROD') {
        // const winUrl = URL.createObjectURL(
        //     new Blob([url], { type: "text/html" })
        // );
        // auth_window = window.open(
        //     winUrl,
        //     "win",
        //     `width=800,height=800,screenX=200,screenY=200`
        // );
        // }else{
        auth_window = window.open(url, '_blank', 'width=800,height=800');
        // }

        if (auth_window !== null) {
            auth_window.opener = null;
            timer = setInterval(function () {
                if (auth_window.closed) {
                    clearInterval(timer);
                    // TODO mandate/callback API and show below messages in
                    _checkMandateStatus(mandate_id);
                }
            }, 1000);
        } else {
            setApiLoader(false);
            toast.dismiss();
            toast.error('Popups are disabled in your browser. Please allow popups to open the payment gateway.', {
                type: 'error'
            });
        }
    }


    function _paymentCallback(payment_id) {
        let close_button = document.getElementById("close-modal");
        close_button.click();
        props.getPaymentStatus(payment_id, firstInvestment === 2 && true, selectedPaymentMethod);
    }

    function _handleMandateChipSelect(key) {
        setMandateAmount(AMOUNT_SUGGESTIONS[key]);
        setActiveMandateChip(key);
        setMandateAmountError("");
    }

    function _handleMandateAmount(value) {
        setMandateAmount(value);
        setMandateAmountError("");
    }
    // API - Get goal summary
    function _fetchGoalDetail() {
        _getGoalInvestmentSummary(goalId, firstInvestment === 1, date, selectedMandate?.id)
            .then((response) => {
                // Need to be removed only response.data should store
                setGoalInvestmentData(response);
                setEnabledSIPDates(response.sip_dates);
                setGoalTypeId(response.goal_data.goal_type_id);
            }).catch((error) => {
                toast.dismiss();
                toast.error(error, {
                    type: "error",
                })
            });
    }

    // API - Verify goal investment
    async function _validateGoalInvestment() {
        let hasMandate = await _validateMandate();
        if (hasMandate === false && props.investmentType === 1) {
            toast.dismiss();
            toast.error("The selected bank doesn't have any mandates. Please create or choose another bank.", {
                type: "error",
            });
            return;
        }
        // if (selectedPaymentMethod === 1) {
        //     if (upiId.length === 0) {
        //         paymentInputRef.current.focus();
        //         setUpiIdError("UPI Id can't be empty");
        //         return;
        //     }

        //     if (upiId.includes("@") === false) {
        //         paymentInputRef.current.focus();
        //         setUpiIdError("Invalid UPI Id");
        //         return;
        //     }
        // }
        setApiLoader(true);
        let url = 'goal/confirm-invest';
        let lumpsum_detail = _checkInvestmentAmountIsZero(goalInvestmentData.goal_data.calculated_lumpsum, 1);
        let sip_detail = _checkInvestmentAmountIsZero(goalInvestmentData.goal_data.calculated_sip, 2);
        let payload = JSON.stringify({
            goal_id: goalTypeId,
            lumpsum: lumpsum_detail,
            sip: sip_detail,
        });

        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                if (response.data.otp_required === false && response.data.skip_payment === true) {
                    let close_button = document.getElementById("close-modal");
                    close_button.click();
                    props.getPaymentStatus(null, firstInvestment === 2 && true, selectedPaymentMethod);
                    return;
                }
                setPaymentOtpRequired(response.data.otp_required);
                setSkipPayment(response.data.skip_payment)
                setStep(2);
                setApiLoader(false);
                if (response.data.otp_required === true) {
                    toast.dismiss();
                    toast.success("An OTP has been sent to your registered phone number", {
                        type: "success",
                    });
                }
            } else {
                setApiLoader(false);
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                });

            }
        });
    }

    // API - Invest in goals
    function _goalInvest() {
        setApiLoader(true);
        let url = 'goal/invest';
        let lumpsum_detail = _checkInvestmentAmountIsZero(goalInvestmentData.goal_data.calculated_lumpsum, 1);
        let sip_detail = _checkInvestmentAmountIsZero(goalInvestmentData.goal_data.calculated_sip, 2);
        let payload = JSON.stringify({
            goal_id: goalTypeId, // goal type id. It must be same with goal listing API
            lumpsum: lumpsum_detail,
            sip: sip_detail,
            upi_id: upiId,
            neft_number: utrNumber,
            payment_type: skipPayment === true ? null : selectedPaymentMethod === 1 ? "UPI" : selectedPaymentMethod === 2 ? "NETBANKING" : "NEFT_RTGS",
            otp: pinString,
            type: null
        });

        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                if (skipPayment === true) {
                    let close_button = document.getElementById("close-modal");
                    close_button.click();
                    props.getPaymentStatus(null, firstInvestment === 2 && true, selectedPaymentMethod);
                    return;
                }
                if (selectedPaymentMethod === 1) {
                    setStep(3)
                } else {
                    setPaymentId(response.data.payment_id);
                    if (response.data.payment_url !== null) {
                        // setApiLoader(false);
                        _openPaymentURL(response.data.payment_url, response.data.payment_html, response.data.payment_id, 2);

                    } else {
                        _paymentCallback(response.data.payment_id);
                    }
                }
            }
            else {
                setApiLoader(false);
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
            }

        })
    }



    // API - MF investment API for order creation
    function _mfInvest() {
        setApiLoader(true);
        const parts = sipEndDate.split('-');
        const formatted_date = parts[2] + '-' + parts[1] + '-' + parts[0];
        let url = 'mf/invest';
        let payload = JSON.stringify({
            mf_id: props.data.id,
            lumpsum: tabIndex === 1 ? null : {
                amount: lumpsumAmount,
                bank_id: selectedOneTimeBank.bank_id
            },
            sip: tabIndex === 2 ? null : {
                amount: amount,
                date: date,
                step_sip: 0,
                generate_today: firstInvestment === 1 ? true : false,
                mandate_id: selectedMandate.id,
                sip_end_date: formatted_date,
                sip_frequency: selectedFrequency
            },
            payment_type: skipPayment === true ? null : selectedPaymentMethod === 1 ? "UPI" : selectedPaymentMethod === 2 ? "NETBANKING" : "NEFT_RTGS",
            otp: pinString,
            type: null,
            folio_number: PROFILE_DATA.demat === true ? '' : selectedFolio.id,
            order_id: mfOrderId,
            upi_id: upiId,
            neft_number: utrNumber
        });

        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                if (skipPayment === true) {
                    let close_button = document.getElementById("close-modal");
                    close_button.click();
                    props.getPaymentStatus(null, firstInvestment === 2 && true, selectedPaymentMethod);
                    return;
                }
                if (selectedPaymentMethod === 1) {
                    setStep(3);
                    setPaymentId(response.data.payment_id);
                } 
                else if ( selectedPaymentMethod === 3) {
                    toast.success(response.data.payment_msg);
                    console.log('inside', response.data.payment_msg);
                    let close_button = document.getElementById("close-modal");
                    close_button.click();
                    // props.getPaymentStatus(null, firstInvestment === 2 && true, selectedPaymentMethod);
                    return;
                }
                else {
                    setPaymentId(response.data.payment_id);
                    if (response.data.payment_url !== null) {
                        _openPaymentURL(response.data.payment_url, response.data.payment_html, response.data.payment_id);

                    } else {
                        _paymentCallback(response.data.payment_id);
                    }
                }
            }
            else {
                setApiLoader(false);
                if (response.data?.in_process === true) {
                    props.showInProcessModal(response.message);
                    let close_button = document.getElementById("close-modal");
                    close_button.click();
                } else {
                    toast.dismiss();
                    toast.error(response.message, {
                        type: "error"
                    });
                }
            }
        });
    }

    // API - Validate MF investment
    async function _validateMFInvestment() {
        if (tabIndex === 1) {
            let hasMandate = await _validateMandate();
            if (hasMandate === false) {
                toast.dismiss();
                toast.error("The selected bank doesn't have any mandates. Please create or choose another bank.", {
                    type: "error",
                });
                return;
            }
        }

        setApiLoader(true);
        const parts = sipEndDate.split('-');
        const formatted_date = parts[2] + '-' + parts[1] + '-' + parts[0];
        let url = 'mf/confirm-invest';
        let payload = JSON.stringify({
            mf_id: props.data.id,
            lumpsum: tabIndex === 1 ? null : {
                amount: lumpsumAmount,
                bank_id: selectedOneTimeBank.bank_id
            },
            sip: tabIndex === 2 ? null : {
                amount: amount,
                date: date,
                step_sip: 0,
                generate_today: firstInvestment === 1 ? true : false,
                mandate_id: selectedMandate.id,
                sip_end_date: formatted_date,
                sip_frequency: selectedFrequency
            },
            type: null,
            folio_number: selectedFolio.id
        });

        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                // existing from the flow if demat user scheduled SIP
                if (response.data.otp_required === false && response.data.skip_payment === true) {
                    let close_button = document.getElementById("close-modal");
                    close_button.click();
                    props.getPaymentStatus(null, firstInvestment === 2 && true, selectedPaymentMethod);
                    return;
                }
                setPaymentOtpRequired(response.data.otp_required);
                setSkipPayment(response.data.skip_payment);
                setStep(2);
                setApiLoader(false);
                if (response.data.otp_required === true) {
                    toast.dismiss();
                    toast.success("An OTP has been sent to your registered phone number", {
                        type: "success",
                    });
                }

                if (process.env.REACT_APP_ENV == 'PROD') {
                    setMfOrderId(response.data.order_id);
                }


            } else {
                setApiLoader(false);
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
            }

        });
    }

    function _fileDownloader(url, file_name) {
        const link = document.createElement('a');
        link.href = url;
        link.download = file_name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        return true;
    };

    // API - Create mandate
    function _handleCreateMandate() {

        if (amount.length === 0 && props.investmentType === 1) {
            setAmountError("SIP amount can't be empty");
            return
        }
        if (amount === 0 && props.investmentType === 1) {
            setAmountError("SIP amount can't be 0");
            return
        }
        let error = false;
        if (mandateAmount == "") {
            setSelectBank(true);
            setMandateAmountError("Amount can't be empty");
            error = true;
        }
        if (expiryDate === null) {
            setSelectBank(true);
            setExpiryDateError("Expiry date in required");
            error = true;
        }
        if (error === true) {
            return;
        }
        setApiLoader(true);
        let mandate_data = null;
        if (mandateType === 2) {
            _createPhysicalMandate(selectedBank.bank_id, mandateAmount, startDate, expiryDate)
                .then(async (response) => {
                    if (response.status_code === 200) {
                        mandate_data = response.data.mandate_id;
                        // Using a function to authenticate mandate recursively until success
                        let result = await _fileDownloader(response.data.url, 'Mandate form');
                        let close_button = document.getElementById("close-modal");
                        close_button.click();
                        props.navigateToProfile();

                    } else {
                        toast.dismiss();
                        toast.error(response.message, {
                            type: 'error'
                        })
                    }

                })
                .catch((error) => {
                    setApiLoader(false);
                    toast.dismiss();
                    toast.error(error.message, {
                        type: 'error'
                    });
                });
            return;
        }
        _createMandate(selectedBank.bank_id, mandateAmount, expiryDate)
            .then((response) => {
                if (response.status_code === 200) {
                    mandate_data = response.data.mandate_id;
                    // Using a function to authenticate mandate recursively until success
                    const authenticateMandate = () => {
                        _authenticateMandate(response.data.mandate_id)
                            .then((response) => {
                                if (response.status_code === 200) {
                                    // If authentication successful, proceed to open payment URL
                                    _openMandateURL(response.authentication_url, mandate_data);
                                } else {
                                    // If authentication failed, retry after 10 second
                                    setTimeout(authenticateMandate, 10000);
                                }
                            })
                            .catch((error) => {
                                setTimeout(authenticateMandate, 10000);
                                // setApiLoader(false);
                                // toast.dismiss();
                                // toast.error(error.message, {
                                //     type: 'error'
                                // });
                            });
                    };
                    // Start authenticating mandate
                    authenticateMandate();
                } else {
                    toast.dismiss();
                    toast.error(response.message, {
                        type: 'error'
                    })
                }

            })
            .catch((error) => {
                setApiLoader(false);
                toast.dismiss();
                toast.error(error.message, {
                    type: 'error'
                });
            });
    }

    // API - authenticate mandate
    function _mandateAuthentication(mandate_id) {
        let timer = setInterval(() => {
            _authenticateMandate(mandate_id).then((response) => {
                console.log('rsponse', response);
                if (response.status_code === 200) {
                    // url is not null open url 
                    if (response.authentication_url !== null) {
                        _openMandateURL(response.authentication_url, mandate_id);
                        clearInterval(timer);
                    }


                }
            })
        }, 5000);
    };

    // API - check mandate status
    function _checkMandateStatus(mandate_id) {
        _mandateStatus(mandate_id).then((response) => {
            if (response.status_code === 200) {
                if (response.data.status === true) {
                    _getInvestmentSummary();

                    let success_msg = 'Mandate registration successful. It might take up to 5 working days to get approved, now step with first installment';
                    if (firstInvestment === 2) {
                        success_msg = 'Mandate registration successful. It might take up to 5 working days to get approved'
                    }
                    toast.success(success_msg, {
                        type: 'success'
                    });
                    setShowCreateNewMandate(false);
                } else {
                    toast.dismiss();
                    toast.error('Mandate is pending', {
                        type: 'error'
                    });
                }
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: 'error'
                })
            }
        })
    }

    // API - Get investment summary after mandate creation
    function _getInvestmentSummary() {
        if (props.investmentType === 1) {
            // API :: getSummary
            _getMFInvestmentSummary(props.data.id, firstInvestment === 1 ? true : false, date, selectMandate.id).then((response) => {
                let bank_data = response.banks;
                let selected_bank_index = bank_data.findIndex(item => item.bank_id === selectedBank.bank_id);
                setSelectedBank(bank_data[selected_bank_index]);
                if (bank_data[selected_bank_index].mandates.length !== 0) {
                    setSelectedMandate(bank_data[selected_bank_index].mandates[0]);
                }
                setMfPropsData(response);
                setFrequencyList(response.data.frequency_list);
                if (response.data.frequency_list.length > 0 && selectedFrequency === "") {
                    setSelectedFrequency(response.data.frequency_list[0]);
                }
            })
        } else {
            // API :: getSummary
            _getGoalInvestmentSummary(goalId, firstInvestment === 1 ? true : false, date).then((response) => {
                let bank_data = response.banks;
                let selected_bank_index = bank_data.findIndex(item => item.bank_id === selectedBank.bank_id);
                setSelectedBank(bank_data[selected_bank_index]);
                if (bank_data[selected_bank_index].mandates.length !== 0) {
                    setSelectedMandate(bank_data[selected_bank_index].mandates[0]);
                }
            })
        }
        setApiLoader(false);
    }

    function _handleCreateMandateLink() {
        setShowCreateNewMandate(true);
    }


    function _checkUPIStatus() {
        let url = 'payment/poll-status';
        let payload = JSON.stringify({
            payment_id: paymentId
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                if (response.data.payment_status_txt === 'pending') {
                    setTimeout(_checkUPIStatus, 1000);
                } else {
                    let close_button = document.getElementById("close-modal");
                    close_button.click();
                    props.getPaymentStatus(paymentId, firstInvestment === 2 && true, selectedPaymentMethod);
                }
            }
        })
    }

    // Investment detail component for goal investment screen;
    function InvestmentDetail(props) {
        return (
            <div className={`row ${props.className}`}>
                <div className="col-6 text-start">
                    <label className="color-dark-jungle-green e-poppins-regular e-font-16 ">{props.label}</label>
                </div>
                <div className="col-6 text-end">
                    <p className="color-dark-jungle-green e-inter-medium e-font-16 margin-block-end-0"
                        style={{ color: props.investmentStyle === true ? _getColorForInvestmentStyle(props.value) : '' }}>
                        {props.value} {props.postfix}
                    </p>

                </div>
            </div>
        )
    };

    const SelectMandate = ({ item, index }) => {
        return (
            <div
                className={
                    selectedBank.mandates.length - 1 !== index ?
                        "border-bottom-1px border-bright-gray padding-12px-all d-flex justify-content-between align-items-center " :
                        "d-flex justify-content-between align-items-center padding-12px-all"
                }
                onClick={() => _handleSelectedMandate(item)}>
                <div>
                    <p className="color-gray e-poppins-regular e-font-12 mb-0">Mandate Id: <span className="color-outer-space">{item.mandate_id}</span></p>
                    <p className="color-gray e-poppins-regular e-font-12 mb-0">Mandate limit: <span className="e-inter-regular">₹{item.mandate_limit.toLocaleString('en-IN')}</span></p>
                </div>
                <Icon icon={selectedMandate.id === item.id ? 'radio-selected' :
                    'radio-unselected'}
                    size={20} />
            </div>
        )
    };

    const SelectBank = ({ item, type }) => {
        const selected_item = type === 1 ? selectedBank : selectedOneTimeBank;

        return (
            <div className={`d-flex justify-content-between align-items-center cursor-pointer gap-8px h-100
                ${item.bank_id === selected_item.bank_id ?
                    `border-radius-8px border-all padding-12px-all border-primary-color`
                    :
                    `border-radius-8px border-all padding-12px-all border-bright-gray`
                }`}

                onClick={() => _handleSelectedBank(item, type)}>
                <div className="d-flex align-items-center">
                    <img src={item.image} alt={item.name}
                        width={35}
                        height={35}
                        className="border-radius-100 me-1" />
                    <div className="d-flex flex-column">
                        <div className="d-flex align-items-center">
                            <span className="color-black e-poppins-semi-bold e-font-14 line-height-14px me-1 ">
                                {item.name}
                            </span>
                            <span className="color-outer-space e-poppins-medium e-font-14 line-height-14px ">{item.account_number}</span>
                        </div>
                        {
                            item.primary === true &&
                            <p className="color-primary-color e-poppins-medium e-font-12  line-height-12px mb-0 mt-1">Primary bank</p>
                        }
                    </div>
                </div>
                <Icon icon={
                    selected_item.bank_id === item.bank_id ?
                        'radio-selected' :
                        'radio-unselected'
                }
                    size={20} />
            </div>
        )
    }

    return (
        <div className={`modal fade  ${style.e_invest_modal}`}
            id="invest-goal"
            tabIndex="-1"
            aria-labelledby="invest-goal"
            aria-hidden="true"
            data-bs-keyboard="false"
            data-bs-backdrop="static" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_invest_dialog}`}>
                <div className={`modal-content p-4 ${style.e_invest_content} `}>
                    <button id="close-modal"
                        data-bs-dismiss="modal"
                        className="d-none"></button>
                    {
                        showUTRInstructions === true ?
                            <>
                                <div className="d-flex postion-relative">
                                    <Icon icon="arrow-right1"
                                        size={24}
                                        color={Color.black}
                                        className={`cursor-pointer ${style.e_back}`}
                                        onClick={_closeUTRInstruction} />
                                    <p className="color-eerie-black ms-2 e-poppins-medium e-font-16 line-height-16px mb-0 mt-1">
                                        NEFT/RTGS details
                                    </p>
                                    <Icon icon="close"
                                        size={24}
                                        data-bs-dismiss="modal"
                                        className={`position-absolute cursor-pointer e-modal-close-btn ${style.e_close}`}
                                        onClick={_closeModal} />
                                </div>
                                <div className="overflow-auto e-scroll-container mt-3">
                                    <div className="col-12">
                                        <p className="color-black e-poppins-regular e-font-16">
                                            Please add the following account in your bank as beneficiary for NEFT/RTGS payments.
                                            Once the beneficiary is setup, you can place and pay for your orders in the following manner.</p>

                                        <ol>
                                            <li className="e-poppins-medium e-font-16 color-black">
                                                {"Individual /Savings Account - Fund Transfer > Transfer to eCMS Account > Third Party Transfer > Add a Beneficiary"}
                                            </li>
                                            <li className="e-poppins-medium e-font-16 color-black mt-3">
                                                {"Non-Individual /Current Account - Fund Transfer > Request > Add a Beneficiary > Transfer to eCMS account"}
                                            </li>
                                        </ol>
                                    </div>

                                    <div className="col-12 text-center">
                                        <p className="e-poppins-medium e-font-14 color-black">Beneficiary Details to be added for NEFT/RTGS transactions</p>
                                    </div>
                                    <div className="col-12">
                                        <table className="utr-instruction-table">
                                            <tr>
                                                <td>Beneficiary Name</td>
                                                <td>Indian Clearing Corporation Limited-MFD A/c</td>
                                            </tr>
                                            <tr>
                                                <td>Beneficiary a/c no</td>
                                                <td>MFDICC</td>
                                            </tr>
                                            <tr>
                                                <td>IFSC Code</td>
                                                <td>HDFC0000240</td>
                                            </tr>
                                            <tr>
                                                <td>Account type</td>
                                                <td>Current</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div className="col-12 text-center mt-3">
                                        <p className="e-poppins-medium e-font-14 color-bright-red">Only NEFT/RTGS model is supported. Do not transfer using IMPS or UPI mode.</p>
                                    </div>
                                    <div className="col-8 offset-2">
                                        <GradientButton label="I understood"
                                            className="e-font-16 padding-12px-tb w-100"
                                            handleClick={_closeUTRInstruction} />
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                {
                                    step === 1 ?
                                        <Fragment>
                                            <p className="color-eerie-black e-poppins-medium e-font-16 line-height-16px margin-12px-bottom">Investment details</p>
                                            <Icon icon="close"
                                                size={24}
                                                data-bs-dismiss="modal"
                                                className={`position-absolute cursor-pointer e-modal-close-btn ${style.e_close}`}
                                                onClick={_closeModal} />

                                            {
                                                props.investmentType === 1 ?
                                                    <div className="d-flex  justify-content-between flex-wrap align-items-center margin-20px-bottom gap-16px">
                                                        <div className={`d-flex gap-16px  align-items-start`}>

                                                            {/* amc image */}
                                                            <div className="border-all border-bright-gray p-1 border-radius-4px">
                                                                <img src={props.data.image}
                                                                    alt={props.data.name}
                                                                    width={48}
                                                                    height={48}
                                                                    className="object-fit-contain" />
                                                            </div>

                                                            <div className="row">
                                                                <div className="col-lg-12">

                                                                    {/* amc name */}
                                                                    <p className="color-black e-poppins-medium e-font-14 line-height-20px mb-0">{props.data.name}</p>

                                                                    {/* folio selection */}
                                                                    {
                                                                        PROFILE_DATA.demat === true ?
                                                                            <span className="border-radius-16px color-polished-pine e-poppins-medium e-font-10 line-height-18px padding-6px-lr py-1 border-polished-pine border-all bg-pale-turquoise">Demat</span>
                                                                            :
                                                                            <div className={`col-12 border-radius-4px border-all border-bright-gray py-1 px-2 d-flex align-items-center justify-content-between position-relative cursor-pointer mt-1 ${style.e_invest_folio}`}
                                                                                ref={FolioRef}
                                                                                onClick={_handleFolioClick}>
                                                                                <p className="color-primary-color e-poppins-medium e-font-10 line-height-12px mb-0">{selectedFolio.id === null ? selectedFolio.label : `Folio no: ${selectedFolio.label}`}</p>
                                                                                <Icon icon="calender-arrow-right"
                                                                                    color={Color.primary}
                                                                                    size={12}
                                                                                    className={showFolioDropDown === true ? style.e_rotate_left_90 : style.e_rotate_right_90} />

                                                                                {/* folio select box */}
                                                                                {
                                                                                    showFolioDropDown === true &&
                                                                                    <div className={`border-radius-8px border-width-2px border-solid border-bright-gray bg-cards position-absolute left-0 w-100 z-index-1 ${style.e_folio}`}>
                                                                                        {
                                                                                            foliosData.map((item, key) =>
                                                                                                <div className={`d-flex align-items-center justify-content-between p-2 cursor-pointer ${foliosData.length !== key + 1 && "border-bottom-1px border-bright-gray"}`}
                                                                                                    key={key}
                                                                                                    onClick={() => _handleFolioSelect(item)}>
                                                                                                    <p className="color-black e-poppins-regular e-font-10 line-height-12px mb-0">{item.label}</p>
                                                                                                    <Icon icon={selectedFolio.id === item.id ? "radio-selected" : "radio-unselected"}
                                                                                                        size={14} />
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <p className="color-black e-poppins-semi-bold e-font-14 line-height-26px mb-0 text-end align-items-center">
                                                                <span className="e-inter-semi-bold">
                                                                    ₹{' '}
                                                                </span>
                                                                {props.data.nav.current_nav.toLocaleString('en-IN')}{' '}
                                                                <span className={`e-font-10 ${props.data.nav.nav_change < 0 ? "color-red" : "color-sea-green"}`}>
                                                                    ({props.data.nav.nav_change} {props.data.nav.nav_change !== '-' && '%'})
                                                                </span>
                                                            </p>
                                                            {
                                                                props.data.nav.nav_date !== null ?
                                                                    <p className="color-gray e-poppins-regular e-font-10 line-height-12px mb-0">(as on {props.data.nav.nav_date})</p>
                                                                    : null
                                                            }

                                                        </div>

                                                    </div>
                                                    :
                                                    // goal image and goal name
                                                    <div className="col-12 d-flex mt-1 mb-3 pb-1">
                                                        <img src={props.goalSummary.goal_data.image}
                                                            width={40}
                                                            height={40}
                                                            alt="" />
                                                        <h3 className="color-black e-poppins-medium e-font-18 mt-2 ms-3">Life goal: {props.goalSummary.goal_data.name}</h3>
                                                    </div>
                                            }

                                            {/* Modal body */}
                                            <div className={`${style.e_modal_body}`} ref={modalBodyRef}>
                                                {
                                                    props.investmentType === 1 ?
                                                        <CustomTab data={tabData}
                                                            selectedTab={tabData.length !== 2 ? tabIndex - 1 : tabIndex}
                                                            wrapperClass={'gap-16px'}
                                                            className={`px-0 padding-10px-tb e-order-tab margin-28px-bottom-sm-16 ${tabData.length !== 2 && 'e-pointer-events-none'}`}
                                                            onSelectTab={_handleTabSelect} />
                                                        :
                                                        <div className="col-12 mb-4">
                                                            <div className="border-radius-16px border-all border-bright-gray p-3">
                                                                <InvestmentDetail label="SIP"
                                                                    value={`₹ ${props.goalSummary.goal_data.calculated_sip.toLocaleString('en-IN')}`} />
                                                                <InvestmentDetail label="Lumpsum amount"
                                                                    value={`₹ ${props.goalSummary.goal_data.calculated_lumpsum.toLocaleString('en-IN')}`} />
                                                                <InvestmentDetail label="Tenure"
                                                                    value={`${props.goalSummary.goal_data.tenure}`}
                                                                    postfix={`${(props.goalSummary.goal_data.tenure === 1) ? `Year` : `Years`}`} />
                                                                <InvestmentDetail label="Risk type"
                                                                    investmentStyle={true}
                                                                    value={`${props.goalSummary.goal_data.investment_style.name}`} />
                                                            </div>
                                                        </div>
                                                }

                                                {/* SIP section */}
                                                {
                                                    tabIndex === 1 ?
                                                        <Fragment>
                                                            <div className="row">
                                                                {/* SIP Input and pre-defined amount selector*/}
                                                                {
                                                                    props.investmentType === 1 && (
                                                                        <div className="col-sm-6 position-relative">
                                                                            {
                                                                                props.data.sip_enabled === true && mfPropsData.sip_minimum_installment_amount && (
                                                                                    <label className="color-black e-font-12 e-poppins-regular position-absolute right-12px top--18">Min. investment:
                                                                                        <span className="e-inter-regular">₹</span> {mfPropsData.sip_minimum_installment_amount.toLocaleString("en-IN")}
                                                                                    </label>
                                                                                )
                                                                            }
                                                                            <CustomAmountInput value={amount}
                                                                                key={"SIP amount"}
                                                                                label="Amount"
                                                                                error={amountError}
                                                                                inputRef={sipInputRef}
                                                                                inputClass=" bg-transparent e-poppins-semi-bold padding-28px-left padding-16px-tb border-radius-12px mt-sm-0 mt-2"
                                                                                labelClass="bg-body-color e-font-16 line-height-26px"
                                                                                handleChange={(value) => _handleInputChange(1, value)} />
                                                                            <div className="d-flex flex-wrap gap-8px mt-4 pt-sm-0 pt-2">
                                                                                {
                                                                                    suggestedSIPAmounts.map((item, key) =>
                                                                                        <Amountchip value={item}
                                                                                            key={key}
                                                                                            amount={amount}
                                                                                            activeChip={activeSIPAmount}
                                                                                            index={key}
                                                                                            handleChipSelect={() => _handleChipSelect(1, key)} />
                                                                                    )
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }
                                                                {
                                                                    props.investmentType === 2 && props.goalSummary.goal_data.calculated_sip > 0 ? (
                                                                        <div className='col-12'>
                                                                            <SIPDateModal modalRef={modalRef}
                                                                                labelClass="e-font-16 line-height-26px"
                                                                                label={`${selectedFrequency} SIP date`}
                                                                                date={date}
                                                                                className="cursor-pointer"
                                                                                inputClass="cursor-pointer"
                                                                                type={1}
                                                                                enabledDates={enabledSIPDates}
                                                                                dateSelected={_handleDateSelected} />
                                                                        </div>
                                                                    ) : props.investmentType === 1 ? (
                                                                        <div className='col-sm-6 mt-sm-0 mt-4 pt-sm-0 pt-1'>
                                                                            <SIPDateModal modalRef={modalRef}
                                                                                labelClass="e-font-16 line-height-26px"
                                                                                label={<span className="d-flex"><div className="e-text-capitalize me-1">{selectedFrequency}</div> SIP date</span>}
                                                                                date={date}
                                                                                className="cursor-pointer"
                                                                                inputClass="cursor-pointer"
                                                                                type={1}
                                                                                enabledDates={enabledSIPDates}
                                                                                dateSelected={_handleDateSelected} />
                                                                        </div>
                                                                    ) : null
                                                                }
                                                                {
                                                                    tabIndex == 1 && props.investmentType === 1 &&
                                                                    <>
                                                                        <div className="mt-4 col-sm-6" >
                                                                            <p className="color-black e-poppins-medium e-font-14 mb-2">
                                                                                SIP end date*
                                                                            </p>
                                                                            <div id="sip-end-date"></div>
                                                                            <DatePicker label=""
                                                                                value={sipEndDate}
                                                                                minDate={sipEndDateMinDate}
                                                                                maxDate={max_date}
                                                                                dateFormat={'dd-MM-yyyy'}
                                                                                displayFormat={'dd MM yyyy'}
                                                                                removeLabelOnFocus={true}
                                                                                error={expiryDateError}
                                                                                calendarClass="position-relative top-0"
                                                                                openDateModal={_handleSIPEndDateFocus}
                                                                                dob={_handleSIPEndDate} />
                                                                        </div>

                                                                    </>
                                                                }

                                                            </div>
                                                            {
                                                                frequencyList.length !== 0 ?
                                                                    <div className=" gap-8px mt-4">
                                                                        <p className="color-black e-poppins-medium e-font-14 mb-3">Set frequency</p>
                                                                        <RadioGroup data={frequencyList}
                                                                            radioItemClassName="py-2"
                                                                            size={14}
                                                                            fontSize="e-font-12"
                                                                            type={4}
                                                                            id={selectedFrequency}
                                                                            radioHandler={_handleFrequency} />
                                                                    </div>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                props.investmentType === 1 &&
                                                                props.data.sip_enabled === true &&
                                                                mfPropsData.sip_minimum_installment_numbers && (
                                                                    <div className="gap-8px mt-3">
                                                                        <label className="color-black e-font-12 e-poppins-regular ">Minimum number of installments:
                                                                            <span className="ms-2">{mfPropsData.sip_minimum_installment_numbers}</span>
                                                                        </label>
                                                                    </div>
                                                                )
                                                            }

                                                            {/* Option to first instalment */}
                                                            <div className="d-flex justify-content-between flex-wrap mt-3">
                                                                {
                                                                    // props.investmentType !== 1 && (props.goalSummary.goal_data.calculated_sip <= 0 || props.goalSummary.goal_data.calculated_lumpsum > 0)?
                                                                    props.investmentType === 2 ?
                                                                        null
                                                                        :
                                                                        <div className="d-flex align-items-center gap-8px">
                                                                            <p className="color-black e-poppins-medium e-font-14 mb-0">Pay first installment now</p>
                                                                            <RadioGroup data={FIRST_INSTALLMENT}
                                                                                radioItemClassName="py-2"
                                                                                size={14}
                                                                                fontSize="e-font-12"
                                                                                type={2}
                                                                                id={firstInvestment}
                                                                                radioHandler={_handleRadioInput} />
                                                                        </div>
                                                                }

                                                                <div className="cursor-pointer d-flex align-items-center gap-8px"
                                                                    onClick={() => _handleSelectBank(1)}>
                                                                    {
                                                                        props.investmentType !== 1 && props.goalSummary.goal_data.calculated_sip <= 0 ?
                                                                            <p className="color-sea-green e-poppins-medium e-font-12 mb-0">Select bank</p>
                                                                            :
                                                                            <p className="color-sea-green e-poppins-medium e-font-12 mb-0">Select bank/mandate</p>
                                                                    }

                                                                    <Icon icon="right-arrow"
                                                                        size={24}
                                                                        className={selectBank === true ? style.e_rotate_left_90 : style.e_rotate_right_90} />
                                                                </div>
                                                            </div>

                                                            {/* Accordion for select bank */}
                                                            {
                                                                loader === false && selectBank === true &&
                                                                <div className="border-radius-8px border-all border-bright-gray p-2 mt-3">
                                                                    <p className="color-outer-space e-poppins-regular e-font-12 mb-0">Select bank</p>
                                                                    <div className="row gx-2">
                                                                        {
                                                                            mfPropsData.banks.map((item, key) =>
                                                                                <div className={`col-lg-6 col-md-6 col-sm-6 col-12 mt-3`} key={key}>
                                                                                    <SelectBank item={item} type={1} />
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>
                                                                    <Fragment>
                                                                        {
                                                                            props.investmentType !== 1 && props.goalSummary.goal_data.calculated_sip <= 0 ?
                                                                                null
                                                                                :
                                                                                <>
                                                                                    {
                                                                                        selectedBank !== undefined && selectedBank.mandates && selectedBank.mandates.length !== 0 ?
                                                                                            <Fragment>
                                                                                                <p className="color-outer-space e-poppins-regular e-font-12 mb-2 mt-3">Select mandate</p>
                                                                                                <div className="d-flex flex-wrap align-items-center gap-24px">
                                                                                                    <div className={`position-relative border-radius-8px border-all border-bright-gray padding-12px-all d-flex justify-content-between align-items-center cursor-pointer ${style.select_bank_radio}`}
                                                                                                        ref={mandateRef}
                                                                                                        onClick={_handleSelectMandate}>
                                                                                                        <div>
                                                                                                            <p className="color-gray e-poppins-regular e-font-12 mb-0">Mandate Id: <span className="color-outer-space">{selectedMandate.mandate_id}</span></p>
                                                                                                            <p className="color-gray e-poppins-regular e-font-12 mb-0">Mandate limit: <span className="e-inter-regular">₹{selectedMandate.mandate_limit?.toLocaleString('en-IN')}</span></p>
                                                                                                        </div>
                                                                                                        <Icon className={selectMandate === true ? style.e_rotate_left_90 : style.e_rotate_right_90}
                                                                                                            icon="calender-arrow-right"
                                                                                                            size={24}
                                                                                                            color={darkMode === true && Color.white} />
                                                                                                        {
                                                                                                            selectMandate === true &&
                                                                                                            <div className={`position-absolute bg-cards border-all border-bright-gray z-index-1 border-radius-8px ${style.e_mandate_drop_down}`}>
                                                                                                                {
                                                                                                                    selectedBank.mandates.map((bankMandate, key) =>
                                                                                                                        <SelectMandate item={bankMandate}
                                                                                                                            key={key}
                                                                                                                            index={key} />
                                                                                                                    )
                                                                                                                }
                                                                                                            </div>
                                                                                                        }

                                                                                                    </div>
                                                                                                    <p className="color-primary-color e-poppins-medium e-font-12 text-decoration-underline mb-0 cursor-pointer"
                                                                                                        onClick={_handleCreateMandateLink}>Create new mandate</p>
                                                                                                </div>
                                                                                            </Fragment>
                                                                                            :
                                                                                            null
                                                                                        // <p className="color-primary-color e-poppins-medium e-font-12 text-decoration-underline mb-0 cursor-pointer mt-3">Create new mandate</p>
                                                                                    }

                                                                                    {
                                                                                        showCreateNewMandate === true &&
                                                                                        <div className="mt-3" id="mandate-amount">
                                                                                            <div className="my-4 d-flex align-items-center gap-8px">
                                                                                                <p className="color-black e-poppins-medium e-font-14 mb-0">Select mandate type</p>
                                                                                                <RadioGroup data={MANDATE_TYPE}
                                                                                                    radioItemClassName="py-2"
                                                                                                    size={14}
                                                                                                    fontSize="e-font-12"
                                                                                                    type={2}
                                                                                                    id={mandateType}
                                                                                                    radioHandler={_handleMandateType} />
                                                                                            </div>
                                                                                            {
                                                                                                mandateType === 2 &&
                                                                                                <div className="row gx-0 my-3">
                                                                                                    <div className="col-12">
                                                                                                        <h5 className="color-jett-black-dark e-poppins-medium e-font-16 ">Set date</h5>
                                                                                                    </div>
                                                                                                    <div className="col-md-6 col-12 pe-md-2">
                                                                                                        <DatePicker label="Start date"
                                                                                                            value={startDate}
                                                                                                            minDate={start_date}
                                                                                                            maxDate={end_date}
                                                                                                            dateFormat={'dd-MM-yyyy'}
                                                                                                            displayFormat={'dd MM yyyy'}
                                                                                                            removeLabelOnFocus={true}
                                                                                                            error={expiryDateError}
                                                                                                            calendarClass="position-relative top-0"
                                                                                                            openDateModal={_handleDatePickerFocus}
                                                                                                            dob={_handleStartDate} />
                                                                                                    </div>
                                                                                                    <div className="col-md-6 col-12 ps-md-2 mt-md-0 mt-4">
                                                                                                        <DatePicker label="End date"
                                                                                                            value={expiryDate}
                                                                                                            minDate={min_date}
                                                                                                            maxDate={max_date}
                                                                                                            dateFormat={'dd-MM-yyyy'}
                                                                                                            displayFormat={'dd MM yyyy'}
                                                                                                            removeLabelOnFocus={true}
                                                                                                            error={expiryDateError}
                                                                                                            calendarClass="position-relative top-0"
                                                                                                            openDateModal={_handleDatePickerFocus}
                                                                                                            dob={_handleDateChange} />
                                                                                                    </div>
                                                                                                    <div className="col-lg-11">
                                                                                                        <div className="d-flex gap-8px bg-mint-cream p-2 w-max-content border-radius-8px mt-3">
                                                                                                            <div className="w-max-content">
                                                                                                                <Icon icon="info"
                                                                                                                    size={24} />
                                                                                                            </div>
                                                                                                            <p className="e-font-14 e-poppins-regular line-height-24px color-jett-black-theme mb-0 ">
                                                                                                                The start date for this process will be effective from T+29 days.
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            }
                                                                                            <h5 className="color-jett-black-dark e-poppins-medium e-font-16 mb-0">Set your mandate limit</h5>
                                                                                            <CustomAmountInput value={mandateAmount}
                                                                                                key={"SIP amount"}
                                                                                                label="Amount"
                                                                                                className="mt-4"
                                                                                                error={mandateAmountError}
                                                                                                inputClass=" bg-transparent e-poppins-semi-bold padding-28px-left  border-radius-12px"
                                                                                                labelClass="bg-body-color e-font-16 line-height-26px"
                                                                                                handleChange={_handleMandateAmount} />
                                                                                            <div className="d-flex flex-wrap gap-8px mt-4 pt-sm-0 pt-2" id="footer-div">
                                                                                                {
                                                                                                    AMOUNT_SUGGESTIONS.map((item, key) => {
                                                                                                        return (
                                                                                                            <Amountchip value={item}
                                                                                                                key={key}
                                                                                                                amount={mandateAmount}
                                                                                                                activeChip={activeMandateChip}
                                                                                                                index={key}
                                                                                                                handleChipSelect={() => _handleMandateChipSelect(key)}
                                                                                                            />
                                                                                                        )
                                                                                                    })
                                                                                                }
                                                                                            </div>
                                                                                            {
                                                                                                mandateType === 1 &&
                                                                                                <div className="my-3">
                                                                                                    <DatePicker label="Expiry date"
                                                                                                        value={expiryDate}
                                                                                                        minDate={min_date}
                                                                                                        maxDate={max_date}
                                                                                                        dateFormat={'dd-MM-yyyy'}
                                                                                                        displayFormat={'dd MM yyyy'}
                                                                                                        removeLabelOnFocus={true}
                                                                                                        error={expiryDateError}
                                                                                                        calendarClass="position-relative top-0"
                                                                                                        openDateModal={_handleDatePickerFocus}
                                                                                                        dob={_handleDateChange} />
                                                                                                </div>
                                                                                            }

                                                                                        </div>
                                                                                    }

                                                                                </>
                                                                        }
                                                                    </Fragment>


                                                                </div>
                                                            }

                                                            {/* SIP detail info section */}
                                                            {
                                                                props.investmentType !== 1 && props.goalSummary.goal_data.calculated_sip <= 0 ?
                                                                    null
                                                                    :
                                                                    <>
                                                                        {
                                                                            // firstInvestment === 1 &&
                                                                            <div className="border-radius-8px bg-pale-yellow mt-4 p-2 d-flex gap-8px">
                                                                                <Icon icon="info"
                                                                                    size={24} />
                                                                                <div>
                                                                                    <div className="d-flex">
                                                                                        <div className={`${style.e_info} margin-32px-right`}>
                                                                                            <p className="color-jett-black-theme e-poppins-regular e-font-12 line-height-24px mb-0">1st SIP Installment</p>
                                                                                            <p className="color-jett-black-theme e-poppins-medium e-font-12 line-height-24px mb-0">
                                                                                                {
                                                                                                    props.investmentType === 1 ?
                                                                                                        mfPropsData.first_installment
                                                                                                        :
                                                                                                        goalInvestmentData.first_installment
                                                                                                }
                                                                                            </p>
                                                                                        </div>
                                                                                        <div>
                                                                                            <p className="color-jett-black-theme e-poppins-regular e-font-12 line-height-24px mb-0">Next SIP Installment</p>
                                                                                            <p className="color-jett-black-theme e-poppins-medium e-font-12 line-height-24px mb-0">
                                                                                                {
                                                                                                    props.investmentType === 1 ?
                                                                                                        mfPropsData.second_installment
                                                                                                        :
                                                                                                        goalInvestmentData.second_installment
                                                                                                }
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <p className="color-jett-black-theme e-poppins-regular e-font-10 line-height-18px mb-2 mt-sm-1 mt-3">Minimum gap between two SIP installments: 30 days. Applicable NAV date will be as per fund realization in Clearing Corporation (ICCL) bank account.</p>
                                                                                    <p className="color-outer-space e-poppins-regular e-font-10 mb-0">By continuing, I agree with the <span className="color-primary-color cursor-pointer" onClick={() => _navigateToLanding('https://www.mastertrust.co.in/disclaimer')}>Disclaimers</span> and <span className="color-primary-color cursor-pointer" onClick={() => _navigateToLanding(`${process.env.REACT_APP_LANDING_PAGE_URL}/terms`)}>T&C</span> of Mastertrust</p>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </>

                                                            }

                                                        </Fragment>
                                                        :
                                                        // Lumpsum Investment section 
                                                        <Fragment>
                                                            {/* Input */}
                                                            <div className="row">
                                                                <div className="col-sm-6 position-relative">
                                                                    {
                                                                        props.data.min_lumpsum_amount !== 0 && (
                                                                            <label className="color-black e-font-12 e-poppins-regular position-absolute right-12px top--18">Min. investment:
                                                                                <span className="e-inter-regular">₹</span> {props.data.min_lumpsum_amount.toLocaleString("en-IN")}
                                                                            </label>
                                                                        )
                                                                    }
                                                                    <CustomAmountInput value={lumpsumAmount}
                                                                        key={"Amount"}
                                                                        label="Amount"
                                                                        error={lumpsumError}
                                                                        inputRef={lumpusmInputRef}
                                                                        inputClass=" bg-transparent e-poppins-semi-bold mt-sm-0 mt-2 padding-28px-left"
                                                                        labelClass="bg-body-color e-font-16 line-height-26px"
                                                                        handleChange={(value) => _handleInputChange(2, value)} />
                                                                </div>
                                                            </div>
                                                            {/* Pre-defined amount selector */}
                                                            {
                                                                suggestedLumpsumAmounts.length !== 0 &&
                                                                <div className="d-sm-flex justify-content-between align-items-center mt-4 pt-sm-0 pt-2">
                                                                    <div className="d-flex gap-8px ">
                                                                        {
                                                                            suggestedLumpsumAmounts.map((item, key) =>
                                                                                <Amountchip value={item}
                                                                                    key={key}
                                                                                    amount={lumpsumAmount}
                                                                                    activeChip={activeLumpsumAmount}
                                                                                    index={key}
                                                                                    handleChipSelect={() => _handleChipSelect(2, key)} />
                                                                            )
                                                                        }
                                                                    </div>
                                                                </div>
                                                            }


                                                            {/* <div className="row mb-3 pb-2">
                                                    <div className="mt-3 col-lg-6 col-md-6 col-12">
                                                        <p className="color-black e-poppins-medium e-font-14 mt-1 mb-3">Choose payment method</p>
                                                        <RadioGroup data={PAYMENT_TYPE}
                                                            radioItemClassName="py-2"
                                                            size={14}
                                                            fontSize="e-font-12"
                                                            type={2}
                                                            id={selectedPaymentMethod}
                                                            radioHandler={_handlePaymentRadio} />
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-12 mt-4 pt-3">
                                                        <CustomTextInput value={upiId}
                                                            key={"UPI ID"}
                                                            label="UPI ID"
                                                            placeholder="sample@upi"
                                                            error={upiIdError}
                                                            inputRef={paymentInputRef}
                                                            inputClass=" bg-transparent e-poppins-semi-bold padding-28px-left padding-16px-tb border-radius-12px"
                                                            labelClass="bg-white e-font-16 line-height-26px"
                                                            handleChange={(value) => _handleInputChange(3, value)} />
                                                    </div>
                                                </div> */}

                                                            <div className="cursor-pointer d-flex align-items-center justify-content-end gap-8px mt-sm-0 mt-3"
                                                                onClick={() => _handleSelectBank(2)}>
                                                                <p className="color-sea-green e-poppins-medium e-font-12 mb-0">Select bank</p>
                                                                <Icon icon="right-arrow"
                                                                    size={24}
                                                                    className={selectOneTimeBank === true ? style.e_rotate_left_90 : style.e_rotate_right_90} />
                                                            </div>

                                                            {/* Bank accounts */}
                                                            {
                                                                loader === false && selectOneTimeBank === true &&
                                                                <div className="border-radius-8px border-all border-bright-gray p-2 mt-3">
                                                                    <p className="color-outer-space e-poppins-regular e-font-12 mb-0">Select bank</p>
                                                                    <div className="row gx-2">
                                                                        {
                                                                            mfPropsData.banks.map((item, key) =>
                                                                                <div className={`col-lg-6 col-md-6 col-sm-6 col-12 mt-3`} key={key}>
                                                                                    <SelectBank item={item} type={2} />
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </div>
                                                            }
                                                        </Fragment>
                                                }
                                            </div>

                                            {/* Modal body end */}
                                            <div className="row mt-3 px-sm-5 px-0">
                                                <div className="col-12 px-sm-5 px-2">
                                                    {
                                                        tabIndex === 2 &&
                                                        <p className="color-outer-space e-poppins-regular e-font-10 mb-2 text-center">By continuing, I agree with the <span className="color-primary-color cursor-pointer" onClick={() => _navigateToLanding('https://www.mastertrust.co.in/disclaimer')}>Disclaimers</span> and <span className="color-primary-color cursor-pointer" onClick={() => _navigateToLanding(`${process.env.REACT_APP_LANDING_PAGE_URL}/terms`)}>T&C</span> of Mastertrust</p>
                                                    }

                                                    {
                                                        props.investmentType === 1 ? (
                                                            <>
                                                                {
                                                                    showCreateNewMandate === true && tabIndex === 1 ?
                                                                        (
                                                                            <GradientButton label="Create Mandate & Proceed"
                                                                                className=" e-font-16 padding-12px-tb w-100"
                                                                                loading={apiLoader}
                                                                                handleClick={_handleCreateMandate} />
                                                                        ) : (
                                                                            <GradientButton label={tabIndex === 1 && firstInvestment === 2 ? "Schedule SIP" : "Proceed"}
                                                                                className=" e-font-16 padding-12px-tb w-100"
                                                                                loading={apiLoader}
                                                                                handleClick={() => step === 1 ? _validateMFData() : _validatePin()} />
                                                                        )
                                                                }
                                                            </>

                                                        ) : (
                                                            <>

                                                                {
                                                                    showCreateNewMandate === true && props.goalSummary.goal_data.calculated_sip > 0 ?
                                                                        (
                                                                            <GradientButton label="Create Mandate & Proceed"
                                                                                className=" e-font-16 padding-12px-tb w-100"
                                                                                loading={apiLoader}
                                                                                handleClick={_handleCreateMandate} />
                                                                        ) : (
                                                                            <GradientButton label={firstInvestment === 2 ? "Schedule SIP" : "Proceed"}
                                                                                className=" e-font-16 padding-12px-tb w-100"
                                                                                loading={apiLoader}
                                                                                handleClick={() => step === 1 ? _validateGoalInvestment() : _validatePin()} />
                                                                        )

                                                                }
                                                            </>
                                                        )

                                                    }
                                                </div>
                                            </div>
                                        </Fragment>
                                        : step === 2 ?
                                            <div className={style.e_otp_section}>
                                                {/* modal header for OTP screen (SIP and Lumpsum) */}
                                                <>
                                                    <Icon icon="arrow-right1"
                                                        size={24}
                                                        color={Color.black}
                                                        className={`position-absolute cursor-pointer ${style.e_back} ${apiLoader === true && "no-events"}`}
                                                        onClick={_handleGoback} />
                                                    <Icon icon="close"
                                                        size={24}
                                                        data-bs-dismiss="modal"
                                                        className={`position-absolute cursor-pointer e-modal-close-btn ${style.e_close}`} />


                                                </>

                                                {/* OTP section */}
                                                <div className="text-center mt-3">
                                                    <img src={require("../../Assets/Images/explore/confirm-payment.png")}
                                                        alt="Confirm payment"
                                                        width={72}
                                                        height={72} />
                                                    {
                                                        paymentOtpRequired === true ? (
                                                            <>
                                                                <p className="color-eerie-black e-poppins-medium e-font-18-sm-16 mb-2 mt-3">Enter OTP you have received in registered email and phone number</p>

                                                                <p className="color-jett-black-theme e-poppins-regular e-font-16 mb-4">You are one step away from making an investment</p>

                                                                <OTPInput pinString={pinString}
                                                                    id="invest"
                                                                    autoFocus={true}
                                                                    pinError={pinError}
                                                                    disabled={apiLoader}
                                                                    handleChange={_handlePin} />
                                                            </>
                                                        ) : (
                                                            <>
                                                                <p className="color-eerie-black e-poppins-medium e-font-18 mb-2 mt-3">Select payment method to generate payment</p>

                                                                <p className="color-jett-black e-poppins-regular e-font-16 mb-4">You are one step away from making an investment</p>

                                                            </>
                                                        )
                                                    }

                                                </div>

                                                {
                                                    skipPayment === false &&
                                                    <div className="row mb-4 mb-lg-0 mb-md-0 pb-2 mt-3 ">
                                                        <div className={`col-12 mb-3  ${paymentOtpRequired === true && 'd-flex align-items-center justify-content-center'}`}>
                                                            <p className="color-black e-poppins-medium e-font-14 mt-1 mb-0">Choose payment method</p>
                                                            <Icon icon="info"
                                                                size={16}
                                                                data-tooltip-id="payment-tooltip"
                                                                className="cursor-pointer ms-2 mt-1" />
                                                            <Tooltip id="payment-tooltip"
                                                                arrowColor='transparent'
                                                                effect="float"
                                                                place="bottom"
                                                                className='e-tooltip-general'>
                                                                Expected timeline for realization of funds as per payment mode.
                                                                <ul className="mb-0">
                                                                    <li>Net Banking (Direct*) - T day</li>
                                                                    <li>Net Banking (Nodal) - T+1 day</li>
                                                                    <li>UPI - T day</li>
                                                                </ul>


                                                                *Direct Banks – Axis, HDFC, ICICI, IDBI, Kotak, SBI & YES Bank

                                                            </Tooltip>
                                                        </div>
                                                        <div className="col-12  ">
                                                            <RadioGroup data={PAYMENT_TYPE}
                                                                radioItemClassName="py-3 border-radius-16px e-payment-radio"
                                                                // size={14}
                                                                activeClass="border-pearl-aqua bg-frosty-cyan"
                                                                fontSize="e-font-12"
                                                                type={2}
                                                                id={selectedPaymentMethod}
                                                                recommendedBadge={true}
                                                                spanClass="e-poppins-semi-bold"
                                                                className="py-2 w-100 flex-sm-nowrap flex-wrap"
                                                                radioHandler={_handlePaymentRadio} />
                                                        </div>
                                                        {
                                                            selectedPaymentMethod === 1 &&
                                                            <div className="col-12 mt-3">
                                                                <CustomTextInput value={upiId}
                                                                    key={"UPI ID"}
                                                                    label="ADD UPI ID*"
                                                                    placeholder="sample@upi"
                                                                    error={upiIdError}
                                                                    inputRef={paymentInputRef}
                                                                    inputClass=" bg-transparent e-poppins-semi-bold padding-28px-left  border-radius-12px"
                                                                    labelClass="bg-modal e-font-16 line-height-26px"
                                                                    handleChange={(value) => _handleInputChange(3, value)} />
                                                            </div>
                                                        }

                                                        {
                                                            selectedPaymentMethod === 3 &&
                                                            <div className="col-12 mt-3 mb-1">
                                                                <CustomTextInput value={utrNumber}
                                                                    key={"UTR ID"}
                                                                    label="Enter UTR number"
                                                                    placeholder="Add UTR number"
                                                                    error={utrNumberError}
                                                                    inputRef={paymentInputRef}
                                                                    inputClass=" bg-transparent e-poppins-semi-bold padding-28px-left  border-radius-12px"
                                                                    labelClass="bg-modal e-font-16 line-height-26px"
                                                                    handleChange={(value) => _handleInputChange(4, value)} />
                                                            </div>
                                                        }
                                                        {
                                                            selectedPaymentMethod === 3 &&
                                                            <div className="col-12 mt-3">
                                                                <p className="color-black e-poppins-light e-font-12 ">
                                                                    <a className="color-primary-color e-poppins-regular text-decoration-underline text-underline-offset-4 cursor-pointer"
                                                                        onClick={_openUTRInstructionModal}>
                                                                        Follow these instructions
                                                                    </a>
                                                                    {' '}
                                                                    to initiate NEFT/RTGS transfer and generate the UTR number
                                                                </p>
                                                            </div>
                                                        }
                                                    </div>
                                                }


                                                {/* place purchase and resend OTP block */}
                                                <div className="mt-3 mx-sm-5 px-sm-5">
                                                    <GradientButton label={firstInvestment === 1 ? "Confirm payment" : paymentOtpRequired === false ? 'Confirm payment' : 'Confirm OTP'}
                                                        className=" e-font-16 padding-12px-tb w-100"
                                                        loading={apiLoader}
                                                        handleClick={_validatePin} />

                                                    {
                                                        paymentOtpRequired === true && (
                                                            <>
                                                                <p className="color-black e-poppins-regular e-font-14-sm-12 line-height-14px mb-0 d-flex justify-content-center align-items-center mt-2 position-relative">
                                                                    OTP will be sent on registered mobile and email{'  '}
                                                                    <div className={`ms- cursor-pointer position-absolute d-flex align-items-center w-max-content ${style.e_payment_icon}`}
                                                                        onMouseEnter={_handleShowMessage}
                                                                        onMouseLeave={_handleHideMessage}>
                                                                        <Icon icon="info"
                                                                            size={16} />
                                                                        {
                                                                            Object.keys(TOOL_TIP).length !== 0 && showMessage === true &&
                                                                            <p className={`position-absolute border-radius-8px border-all border-bright-gray color-outer-space e-poppins-regular bg-white p-2 e-font-12 line-height-14px mb-0 ${style.e_email}`}>
                                                                                {parse(TOOL_TIP.invest_investment_popup)}
                                                                            </p>
                                                                        }
                                                                    </div>
                                                                </p>

                                                                <div className="d-flex justify-content-center mt-4">
                                                                    {
                                                                        seconds === 0 ?
                                                                            <p className='color-primary-color e-poppins-regular e-font-14 line-height-14px cursor-pointer mb-0 e-forgot-pin position-relative w-max-content'
                                                                                onClick={_handleResendOTP}>Resend OTP</p>
                                                                            :
                                                                            <p className='color-primary-color e-poppins-regular e-font-14 line-height-14px cursor-not-allowed mb-0 '>
                                                                                Resend OTP in <span className="color-black">{seconds}s</span>
                                                                            </p>
                                                                    }

                                                                </div>
                                                            </>
                                                        )
                                                    }

                                                </div>
                                            </div>
                                            :
                                            <Fragment>
                                                {/* <Icon icon="close"
                                        size={24}
                                        data-bs-dismiss="modal"
                                        className={`position-absolute cursor-pointer e-modal-close-btn ${style.e_close}`} /> */}

                                                <button id="close-modal"
                                                    data-bs-dismiss="modal"
                                                    className="d-none"></button>
                                                <div className="text-center">
                                                    {/* <img src={require("../../Assets/Images/explore/confirm-payment.png")}
                                            alt="Confirm payment"
                                            width={72}
                                            height={72} /> */}
                                                    <p className="color-eerie-black e-poppins-medium e-font-18 mb-2 mt-3">Transaction initiated, awaiting payment from UPI app</p>

                                                    <p className="color-black e-font-28 e-inter-medium ">
                                                        <span className="e-font-14 vertical-sup">₹</span>
                                                        {props.investmentType === 2 ? goalInvestmentData.goal_data.calculated_sip.toLocaleString('en-IN') : tabIndex === 2 ? lumpsumAmount.toLocaleString('en-IN') : amount.toLocaleString('en-IN')}
                                                    </p>
                                                    <div className="d-flex justify-content-center">
                                                        <div className="bg-ghost-white border-radius-16px px-5 py-2 w-fit-content">
                                                            <p className="e-font-14 e-poppins-medium mb-0">Approve payment within</p>
                                                            <p className="e-font-24 e-poppins-regular mb-0">
                                                                {`${upiTimerSecond !== 600 ? '0' : ''}${Math.floor(upiTimerSecond / 60)} : ${upiTimerSecond % 60 < 10 ? '0' : ''}${upiTimerSecond % 60}`}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mt-4">
                                                    <h6 className="mb-3 ">UPI Payment Instructions</h6>
                                                    <ul className="ps-3">
                                                        <li className="e-font-14 e-poppins-regular color-outer-space mb-2">
                                                            Open your UPI app & confirm the payment via registered bank account with mt wealth.
                                                        </li>
                                                        <li className="e-font-14 e-poppins-regular color-outer-space  mb-2">
                                                            You may close this window after approving the payment in UPI app.
                                                        </li>
                                                    </ul>
                                                </div>
                                            </Fragment>
                                }
                            </>
                    }

                </div>
            </div>
        </div >
    )
}

InvestGoalModal.defaultProps = {
    investmentType: 1,
    selectedTab: 1,
    close: () => { },
    amount: '',
    mfInvestmentData: {
        first_installment: '',
        second_installment: '',
        banks: [],
        folios: []
    }
}